import { createSlice, createAsyncThunk, autoBatchEnhancer } from '@reduxjs/toolkit';
import { AUTH_TOKEN, USERNAME, MODE, BRANCH, USERID } from 'constants/AuthConstant';
import AuthMockupService from 'services/AuthMockupService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	userBranch: null,
	selectedBranch: localStorage.getItem(BRANCH) || null,
	mode: localStorage.getItem(MODE) || null,
	userId: localStorage.getItem(USERID) || null

}

export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue }) => {
	const response = await AuthMockupService.login(data)
	if (response.status === 201 || response.status === 200 ) {
		try {			
			localStorage.setItem(AUTH_TOKEN, response.data.token);
			localStorage.setItem(MODE, (data.userId.toLowerCase() == 'admin') ? 'am' : 'cabang');
			localStorage.setItem(USERID, data.userId);
			localStorage.setItem(USERNAME, response.data.userName);
			return response.data.branch;
		} catch (err) {			
			return rejectWithValue(err.response?.data?.message || 'Error')
		}
	}
})

export const signOut = createAsyncThunk('auth/logout', async () => {
	const response = await AuthMockupService.logout()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(USERID);
	localStorage.removeItem(USERNAME);
	localStorage.removeItem(MODE);
	localStorage.removeItem(BRANCH);
	return response
})

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			localStorage.setItem(BRANCH,  JSON.stringify(action.payload));
			state.selectedBranch = action.payload
			state.token = localStorage.getItem(AUTH_TOKEN)
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
			state.selectedBranch = null
			state.mode = null
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		},		
	},
	extraReducers: (builder) => {
		builder
			//signIn
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.mode = localStorage.getItem(MODE)
				state.userBranch = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			//signOut
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.selectedBranch = null
				state.redirect = '/'
				state.userBranch = null
				state.mode = null
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
			})					
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer