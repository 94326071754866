import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import employee from "./slices/setup/organization/employeeSlice";
import branch from "./slices/setup/organization/branchSlice";
import productTC from "./slices/setup/organization/productTcSlice";
import prospect from "./slices/prospect/prospectSlice";
import products from "./slices/setup/organization/productsSlice";
import productTypeOfUse from "./slices/master/productTypeOfUseSlice";
import masterTc from "./slices/master/masterTcSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    employee,
    branch,
    prospect,
    products,
    productTypeOfUse,
    masterTc,
    productTC,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
