import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import masterTcService from 'services/Setup/Master/masterTcService'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
    result: {},
    masterTcList: {}
}

export const postFee = createAsyncThunk('fee/postFee', async (data, { rejectWithValue }) => {
    try {
        const response = await masterTcService.postFee(data)
        console.log('response',response);
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', response: 'Unexpected repsonse!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const getMasterTcList = createAsyncThunk('masterTc/getMasterTcList', async (data, { rejectWithValue }) => {
    console.log("🚀 ~ file: masterTcSlice.js:30 ~ getMasterTcList ~ data:", data)
    try {
        const response = await masterTcService.getMaterTcList()
        console.log("🚀 ~ file: masterTcSlice.js:33 ~ getMasterTcList ~ response:", response)
        if (response) {
            console.log('get list executed')
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        console.log("🚀 ~ file: masterTcSlice.js:32 ~ getMasterTcList ~ response:", error)
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const getFeeDetail = createAsyncThunk('fee/getFeeDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await masterTcService.getFeeDetail(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})


export const updateFee = createAsyncThunk('fee/updateFee', async (data, { rejectWithValue }) => {
    try {
        const response = await masterTcService.updateFee(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})


export const deleteFee = createAsyncThunk('fee/deleteFee', async (data, { rejectWithValue }) => {
    try {
        const response = await masterTcService.deleteFee(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const masterTc = createSlice({
    name: 'masterTc',
    initialState,
    reducers: {
        showNotification: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideNotification: (state) => {
            state.message = ''
            state.showMessage = false
        },
        showLoading: (state) => {
            state.loading = true
        }
    },

    extraReducers: (builder) => {
        builder
            //post
            .addCase(postFee.pending, (state) => {
                state.loading = true
            })
            .addCase(postFee.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.result = action.payload
            })
            .addCase(postFee.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //list
            .addCase(getMasterTcList.pending, (state) => {
                state.loading = true
                state.redirect = ''
            })
            .addCase(getMasterTcList.fulfilled, (state, action) => {
                console.log('action ----')
                state.loading = false
                state.masterTcList = action.payload  
            })
            .addCase(getMasterTcList.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //detail
            .addCase(getFeeDetail.pending, (state) => {
                state.loading = true
            })
            .addCase(getFeeDetail.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload
            })
            .addCase(getFeeDetail.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //update
            .addCase(updateFee.pending, (state) => {
                state.loading = true
            })
            .addCase(updateFee.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.result = action.payload
            })
            .addCase(updateFee.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //delete
            .addCase(deleteFee.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteFee.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload
            })
            .addCase(deleteFee.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
    }

})

export const {
    showNotification,
    hideNotification,
    showLoading
} = masterTc.actions

export default masterTc.reducer