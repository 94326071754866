import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import { connectFirestoreEmulator } from "firebase/firestore/lite";
import { act } from "react-dom/test-utils";
import ProductsService from "services/Setup/Organization/productsService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  result: {},
  productList: {},
  refreshList: false,
  productApprovalList: {},
  getPageSize: "",
  getPageIndex: "",
  getCount: "",
};

export const postProducts = createAsyncThunk(
  "products/postProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductsService.postProducts(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", response: "Unexpected repsonse!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const getProductsList = createAsyncThunk(
  "products/getProductsList",
  async (userInput, { rejectWithValue }) => {
    try {
      const response = await ProductsService.getProductsList(userInput);
      if (response) {
        return response.data;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const getProductApprovalList = createAsyncThunk(
  "products/getProductApprovalList",
  async (userInput, { rejectWithValue }) => {
    try {
      const response = await ProductsService.getProductApprovalList(userInput);
      if (response) {
        return response.data;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const putProductApproval = createAsyncThunk(
  "products/putProductApproval",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductsService.putProductApproval(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", response: "Unexpected repsonse!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const putProductRequest = createAsyncThunk(
  "products/putProductRequest",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductsService.putProductRequest(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", response: "Unexpected repsonse!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const getProductsDetail = createAsyncThunk(
  "products/getProductsDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductsService.getProductsDetail(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const updateProducts = createAsyncThunk(
  "products/updateProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductsService.updateProducts(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const deleteProducts = createAsyncThunk(
  "products/deleteProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProductsService.deleteProducts(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideNotification: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    showLoading: (state) => {
      state.loading = true;
    },
  },

  extraReducers: (builder) => {
    builder
      //post
      .addCase(postProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(postProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.result = action.payload;
        state.showMessage = true;
        state.message = action.payload.data.message;
        state.refreshList = true;
      })
      .addCase(postProducts.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      })
      //list
      .addCase(getProductsList.pending, (state) => {
        state.loading = true;
        state.redirect = "";
      })
      .addCase(getProductsList.fulfilled, (state, action) => {
        state.loading = false;
        state.productList = action.payload;
        state.getCount = action.payload.count;
      })
      .addCase(getProductsList.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      })
      //product approval list
      .addCase(getProductApprovalList.pending, (state) => {
        state.loading = true;
        state.redirect = "";
      })
      .addCase(getProductApprovalList.fulfilled, (state, action) => {
        state.loading = false;
        state.productApprovalList = action.payload;
        state.getCount = action.payload.count;
      })
      .addCase(getProductApprovalList.rejected, (state, action) => {
        state.loading = false;
        state.productApprovalList = action.payload;
      })
      //product approval approve
      .addCase(putProductApproval.pending, (state) => {
        state.loading = true;
        state.redirect = "";
      })
      .addCase(putProductApproval.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload;
      })
      .addCase(putProductApproval.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      })
      //product approval approve
      .addCase(putProductRequest.pending, (state) => {
        state.loading = true;
        state.redirect = "";
      })
      .addCase(putProductRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload;
      })
      .addCase(putProductRequest.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      })
      //detail
      .addCase(getProductsDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductsDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload;
      })
      .addCase(getProductsDetail.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      })
      //update
      .addCase(updateProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.result = action.payload;
      })
      .addCase(updateProducts.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      })
      //delete
      .addCase(deleteProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.result = action.payload;
      })
      .addCase(deleteProducts.rejected, (state, action) => {
        state.loading = false;
        state.result = action.payload;
        state.showMessage = true;
      });
  },
});

export const {
  //    update,
  showNotification,
  hideNotification,
  showLoading,
} = productsSlice.actions;

export default productsSlice.reducer;
