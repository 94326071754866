import fetch from 'auth/FetchMockupInterceptor'

const ProspectService = {}

ProspectService.postProspect = function (data) {
    return fetch({
        url: '/prospect',
        method: 'post',
        data: data
    })
}

ProspectService.getProspectList = function (data) {
    return fetch({
        url: '/prospect',
        method: 'get',
        data: data
    })
}

ProspectService.getProspectDetail = function (data) {
    return fetch({
        url: `/prospect/${data.id}`,
        method: 'get'
    })
}

ProspectService.updateProspect = function (data) {
    return fetch({
        url: `prospect/${data.id}`,
        method: 'put',
        data: data

    })
}

ProspectService.deleteProspect = function (data) {
    return fetch({
        url:`prospect/${data.id}`,
        method: 'delete'
    })
}

export default ProspectService