import fetch from 'auth/FetchMockupInterceptor'

const BranchService = {}

BranchService.getBranchList = function () {
    return fetch({
        url: '/branch',
        method: 'get'
    })
}

export default BranchService