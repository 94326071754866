import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProductTypeOfUseService from "services/Setup/Master/productTypeOfUseService";

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    productTypeOfUseList: [{ code: '', name: ''}],
    response: {}
}

export const getProductTypeOfUseList = createAsyncThunk('master/getProductTypeOfUseList', async () => {           
       const response = await ProductTypeOfUseService.getProductTypeOfUseList()
       if (response) {            
            return response.data
       } else {
            console.log('error')
            return ({data: 'None'})
       }       
})

export const productTypeOfUseSlice = createSlice({
    name: 'productTypeOfUse',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProductTypeOfUseList.pending, (state) => {
                state.loading = true
            })
            .addCase(getProductTypeOfUseList.fulfilled, (state, action) => {
                state.loading = false
                state.productTypeOfUseList = action.payload
            })
            .addCase(getProductTypeOfUseList.rejected, (state, action) => {
                state.loading = false
                state.response = action.payload
                state.showMessage = true
            })
    }
})

export const { showLoading } = productTypeOfUseSlice.actions

export default productTypeOfUseSlice.reducer