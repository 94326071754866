import fetch from 'auth/FetchMockupInterceptor'

const EmployeeService = {}

EmployeeService.postEmployee = function (data) {
    return fetch({
        url: '/employee',
        method: 'post',
        data: data
    })
}

EmployeeService.getEmployeeList = function (data) {
    return fetch({
        url: '/employee',
        method: 'get',
        data: data
    })
}

EmployeeService.getEmployeeDetail = function (data) {
    return fetch({
        url: `/employee/${data.id}`,
        method: 'get'
    })
}

EmployeeService.updateEmployee = function (data) {
    return fetch({
        url: `employee/${data.id}`,
        method: 'put',
        data: data

    })
}

EmployeeService.deleteEmployee = function (data) {
    return fetch({
        url:`employee/${data.id}`,
        method: 'delete'
    })
}

export default EmployeeService
