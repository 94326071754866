import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import BranchService from "services/Setup/Organization/branchService";

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    branchList: [{ id:'0', branchId:'0', branchName:'No Data'}],
    response: {}
}

export const getBranchList = createAsyncThunk('branch/getBranchList', async () => {           
       const response = await BranchService.getBranchList()  
       if (response) {            
            return response.data
       } else {
            console.log('error')
            return ({data: 'None'})
       }       
})

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranchList.pending, (state) => {
                state.loading = true
            })
            .addCase(getBranchList.fulfilled, (state, action) => {
                state.loading = false
                state.branchList = action.payload
            })
            .addCase(getBranchList.rejected, (state, action) => {
                state.loading = false
                state.response = action.payload
                state.showMessage = true
            })
    }
})

export const { showLoading } = branchSlice.actions

export default branchSlice.reducer