const dev = {
  // API_ENDPOINT_URL: 'https://071cc350-ba4f-4d2a-90fd-b6547f99362c.mock.pstmn.io/v1'
  //API_ENDPOINT_URL: '/api'
  // Local endpoint
  // API_ENDPOINT_URL: 'http://192.168.2.24:89/api/los', 
  // Public endpoint
  API_ENDPOINT_URL: 'https://api-los-fintrust.clientapphost.com/api/los',
  API_ENDPOINT_MOCKUO_URL: 'https://mock.apidog.com/m1/388811-0-default'
};

const prod = {
  API_ENDPOINT_URL: 'https://api-los-fintrust.clientapphost.com/api/los',
  API_ENDPOINT_MOCKUO_URL: "https://mock.apidog.com/m1/388811-0-default",
};

const test = {
  API_ENDPOINT_URL: 'https://api-los-fintrust.clientapphost.com/api/los',
  API_ENDPOINT_MOCKUO_URL: "https://mock.apidog.com/m1/388811-0-default",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
