import fetch from "auth/FetchInterceptor";

const ProductTcService = {};

ProductTcService.postProductTc = function (data) {
  return fetch({
    url: `/producttc/add`,
    method: "post",
    headers: {
      "x-RequestId": data.id,
    },
    data: {
      ProductId: data.id,
      ProductTCs: data.ProductTCs,
      userid: "test",
    },
  });
};

ProductTcService.getProductTcList = function (data) {
  return fetch({
    url: "/producttype",
    method: "get",
    data: data,
  });
};

ProductTcService.getProductTcDetail = function (data) {
  return fetch({
    url: `/producttype/${data}`,
    method: "get",
  });
};

ProductTcService.updateProductTc = function (data) {
  return fetch({
    url: `/producttc/edit`,
    method: "put",
    headers: {
      "x-RequestId": data.id,
    },
    data: {
      ProductTCs: data.ProductTCs,
      userid: "test",
    },
  });
};

ProductTcService.deleteProductTc = function (data) {
  return fetch({
    url: `/producttc/delete`,
    method: "delete",
    headers: {
      "x-RequestId": data.id,
    },
    data: {
      ProductId: data.id,
      ProductTCs: data.ProductTCs,
      userid: "test",
    },
  });
};
ProductTcService.returnProductTc = function (data) {
  return fetch({
    url: `/producttc/return`,
    method: "put",
    headers: {
      "x-RequestId": data.id,
    },
    data: {
      ProductId: data.id,
      ProductTCs: data.ProductTCs,
      userid: "test",
    },
  });
};
ProductTcService.approveProductTc = function (data) {
  return fetch({
    url: `/producttc/approve`,
    method: "put",
    headers: {
      "x-RequestId": data.id,
    },
    data: {
      ProductId: data.id,
      ProductTCs: data.ProductTCs,
      userid: "test",
    },
  });
};

export default ProductTcService;
