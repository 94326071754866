import fetch from "auth/FetchInterceptor";
import { increment } from "firebase/firestore/lite";
import { stringify } from "postcss";

const ProductsService = {};

ProductsService.postProducts = function (data) {
  return fetch({
    url: "/producttype/add",
    method: "post",
    data: data,
  });
};

ProductsService.getProductsList = function (userInput) {
  var userInputIndex = userInput.pageIndex;
  console.log(
    "🚀 ~ file: productsService.js:17 ~ userInputIndex:",
    userInputIndex
  );
  var decrementIndex =
    userInputIndex === 0 ? userInputIndex : userInputIndex - 1;
  console.log(
    "🚀 ~ file: productsService.js:22 ~ decrementIndex:",
    decrementIndex
  );
  return fetch({
    url:
      "/producttype/search?pageSize=" +
      userInput.pageSize +
      "&pageIndex=" +
      decrementIndex +
      "&code=" +
      userInput.prodCod +
      "&desc=" +
      userInput.desc +
      "&status=" +
      userInput.status,
    method: "get",
  });
};

ProductsService.getProductApprovalList = function (userInput) {
  var userInputIndex = userInput.pageIndex;
  console.log(
    "🚀 ~ file: productsService.js:45 ~ userInputIndex:",
    userInputIndex
  );
  var decrementIndex =
    userInputIndex === 0 ? userInputIndex : userInputIndex - 1;
  console.log(
    "🚀 ~ file: productsService.js:22 ~ decrementIndex:",
    decrementIndex
  );
  return fetch({
    url:
      "/producttype/approvalItems?pageSize=" +
      userInput.pageSize +
      "&pageIndex=" +
      decrementIndex +
      "&code=" +
      userInput.prodCod +
      "&desc=" +
      userInput.desc,
    // +
    // "&status=" +
    // userInput.status,
    method: "get",
  });
};

ProductsService.putProductApproval = function (data) {
  const validData =
    data.approvalFlag.toLowerCase() == "return" ||
    data.approvalFlag.toLowerCase() == "reject"
      ? { userId: data.userId }
      : {
          note: data.note,
          userId: data.userId,
        };

  return fetch({
    url: `/producttype/${data.approvalFlag}`,
    method: "put",
    headers: {
      "x-requestid": data.id,
    },
    data: validData,
  });
};

ProductsService.putProductRequest = function (data) {
  return fetch({
    url: `/producttype/${data.approvalFlag}`,
    method: "put",
    headers: {
      "x-requestid": data.id,
    },
    data: data,
  });
};

ProductsService.getProductsDetail = function (data) {
  return fetch({
    url: `/producttype/${data.id}`,
    method: "get",
  });
};

ProductsService.updateProducts = function (data) {
  return fetch({
    url: "/producttype/edit",
    method: "put",
    headers: {
      "x-requestid": data.id,
    },
    data: data,
  });
};

ProductsService.deleteProducts = function (data) {
  return fetch({
    url: "/producttype/delete",
    method: "delete",
    headers: {
      "x-requestid": data.id,
    },
    data: data,
  });
};

export default ProductsService;
