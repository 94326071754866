import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import ProspectService from 'services/prospect/prospectService'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
    result: {},
    prospectList: {}
}

export const postProspect = createAsyncThunk('Prospect/postProspect', async (data, { rejectWithValue }) => {
    try {
        const response = await ProspectService.postProspect(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', response: 'Unexpected repsonse!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const getProspectList = createAsyncThunk('Prospect/getProspectList', async (data, { rejectWithValue }) => {
    try {
        const response = await ProspectService.getProspectList(data)
        if (response) {
            console.log('get list executed')
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const getProspectDetail = createAsyncThunk('Prospect/getProspectDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await ProspectService.getProspectDetail(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})


export const updateProspect = createAsyncThunk('Prospect/updateProspect', async (data, { rejectWithValue }) => {
    try {
        const response = await ProspectService.updateProspect(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})


export const deleteProspect = createAsyncThunk('Prospect/deleteProspect', async (data, { rejectWithValue }) => {
    try {
        const response = await ProspectService.deleteProspect(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const ProspectSlice = createSlice({
    name: 'prospect',
    initialState,
    reducers: {
        showNotification: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideNotification: (state) => {
            state.message = ''
            state.showMessage = false
        },
        showLoading: (state) => {
            state.loading = true
        }
    },
    extraReducers: (builder) => {
        builder
            //post
            .addCase(postProspect.pending, (state) => {
                state.loading = true
            })
            .addCase(postProspect.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.result = action.payload
            })
            .addCase(postProspect.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //list
            .addCase(getProspectList.pending, (state) => {
                state.loading = true
                state.redirect = ''
            })
            .addCase(getProspectList.fulfilled, (state, action) => {
                state.loading = false
                state.ProspectList = action.payload
                console.log('action')
            })
            .addCase(getProspectList.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //detail
            .addCase(getProspectDetail.pending, (state) => {
                state.loading = true
            })
            .addCase(getProspectDetail.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload
            })
            .addCase(getProspectDetail.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //update
            .addCase(updateProspect.pending, (state) => {
                state.loading = true
            })
            .addCase(updateProspect.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.result = action.payload
            })
            .addCase(updateProspect.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //delete
            .addCase(deleteProspect.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteProspect.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload
            })
            .addCase(deleteProspect.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
    }

})

export const {
    showNotification,
    hideNotification,
    showLoading
} = ProspectSlice.actions

export default ProspectSlice.reducer