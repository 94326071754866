import fetch from 'auth/FetchInterceptor'

const ProductTypeOfUseService = {}

ProductTypeOfUseService.getProductTypeOfUseList = function (data) {
    return fetch({
        url: '/reference/typeofuse',
        method: 'get'
    })
}

export default ProductTypeOfUseService