import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH, COMPONENTS_PATH } from "configs/AppConfig";

export const components = [
  {
      key: 'productComponent',
      path: `${COMPONENTS_PATH}/productComponent`,
      component: React.lazy(() => import('components/shared-components/ProductComponent')),
  },
  {
      key: 'productComponent.productDetail',
      path: `${COMPONENTS_PATH}/ProductComponent/ProductDetail`,
      component: React.lazy(() => import('components/shared-components/ProductComponent/ProductDetail')),
  },
  {
      key: 'productComponent.productDocDetail',
      path: `${COMPONENTS_PATH}/ProductComponent/ProductDocDetail`,
      component: React.lazy(() => import('components/shared-components/ProductComponent/ProductDocDetail')),
  },
  {
      key: 'productComponent.productTCDetail',
      path: `${COMPONENTS_PATH}/ProductComponent/ProductTCDetail`,
      component: React.lazy(() => import('components/shared-components/ProductComponent/ProductTCDetail')),
  },
  {
      key: 'productComponent.WorkFlowDetail',
      path: `${COMPONENTS_PATH}/ProductComponent/WorkFlowDetail`,
      component: React.lazy(() => import('components/shared-components/ProductComponent/WorkFlowDetail')),
  },
]

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "cabang",
    path: `${AUTH_PREFIX_PATH}/branch`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/branch")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "appmanager.appadmin",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin`,
    component: React.lazy(() => import("views/app-views/appmanager/appadmin")),
  },
  {
    key: "appmanager.appadmin.usergroup",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/usergroup`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/usergroup")
    ),
  },
  {
    key: "appmanager.appadmin.usergroup.userlist",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/usergroup/userlist`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/usergroup/userlist")
    ),
  },
  {
    key: "appmanager.appadmin.usergroup.grouplist",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/usergroup/grouplist`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/usergroup/grouplist")
    ),
  },

  {
    key: "appmanager.appadmin.appcomponent",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/appcomponent`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/appcomponent")
    ),
  },
  {
    key: "appmanager.appadmin.appcomponent.formfeature",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/appcomponent/formfeature`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/appcomponent/formfeature")
    ),
  },
  {
    key: "appmanager.appadmin.appcomponent.menueditor",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/appcomponent/menueditor`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/appcomponent/menueditor")
    ),
  },
  {
    key: "appmanager.appadmin.thingstodo",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/thingstodo`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/thingstodo")
    ),
  },
  {
    key: "appmanager.appadmin.thingstodo.groupalert",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/thingstodo/groupalert`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/thingstodo/groupalert")
    ),
  },
  {
    key: "appmanager.appadmin.thingstodo.systemalert",
    path: `${APP_PREFIX_PATH}/appmanager/appadmin/thingstodo/systemalert`,
    component: React.lazy(() =>
      import("views/app-views/appmanager/appadmin/thingstodo/systemalert")
    ),
  },
  {
    key: "coremenu.marketing",
    path: `${APP_PREFIX_PATH}/coremenu/marketing`,
    component: React.lazy(() => import("views/app-views/coremenu/marketing")),
  },
  {
    key: "coremenu.marketing.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.negativeCustomer",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/negativeCustomer`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/negativeCustomer")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.cekEksternalData",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/cekEksternalData`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/cekEksternalData")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.polingCabangAplikasiProspek",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/polingCabangAplikasiProspek`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/transaksi/polingCabangAplikasiProspek"
      )
    ),
  },
  {
    key: "coremenu.marketing.transaksi.keputusanProspect",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/keputusanProspect`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/keputusanProspect")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.plafondRequest",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/plafondRequest`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/plafondRequest")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.supplier",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/supplier`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/supplier")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.privateSupplier",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/privateSupplier`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/privateSupplier")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.supplierAccountChangeRequest",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/supplierAccountChangeRequest`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/transaksi/supplierAccountChangeRequest"
      )
    ),
  },
  {
    key: "coremenu.marketing.transaksi.supplierAccountApproval",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/supplierAccountApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/transaksi/supplierAccountApproval"
      )
    ),
  },
  {
    key: "coremenu.marketing.transaksi.supplierGroup",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/supplierGroup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/supplierGroup")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.vospay",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/vospay`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/transaksi/vospay")
    ),
  },
  {
    key: "coremenu.marketing.transaksi.vospay.vospayInvoice",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/vospay/vospayInvoice`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/transaksi/vospay/vospayInvoice"
      )
    ),
  },
  {
    key: "coremenu.marketing.transaksi.vospay.vospayInvoiceReport",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/transaksi/vospay/vospayInvoiceReport`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/transaksi/vospay/vospayInvoiceReport"
      )
    ),
  },

  {
    key: "coremenu.marketing.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/inquiry")
    ),
  },
  {
    key: "coremenu.marketing.inquiry.aplikasiMasuk",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/inquiry/aplikasiMasuk`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/inquiry/aplikasiMasuk")
    ),
  },

  {
    key: "coremenu.marketing.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/laporan")
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/laporan/penjualan")
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.realisasiPenjualanbyCMO",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/realisasiPenjualanbyCMO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/realisasiPenjualanbyCMO"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.laporanSalesNV",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/laporanSalesNV`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/laporanSalesNV"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.realisasiPenjualanbySupplier",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/realisasiPenjualanbySupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/realisasiPenjualanbySupplier"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.realisasiPenjualanbyTenor",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/realisasiPenjualanbyTenor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/realisasiPenjualanbyTenor"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.targetSalesvsAktual",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/targetSalesvsAktual`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/targetSalesvsAktual"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.laporanRekorBooking",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/laporanRekorBooking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/laporanRekorBooking"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.laporanAplikasiBuyBackGuarantee",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/laporanAplikasiBuyBackGuarantee`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/laporanAplikasiBuyBackGuarantee"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.penjualan.laporanSalesIC",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/penjualan/laporanSalesIC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/penjualan/laporanSalesIC"
      )
    ),
  },

  {
    key: "coremenu.marketing.laporan.daftarSupplier",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/daftarSupplier`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/laporan/daftarSupplier")
    ),
  },
  {
    key: "coremenu.marketing.laporan.daftarEmployeeSupplier",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/daftarEmployeeSupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/laporan/daftarEmployeeSupplier"
      )
    ),
  },
  {
    key: "coremenu.marketing.laporan.laporanProspect",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/laporan/laporanProspect`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/laporan/laporanProspect")
    ),
  },

  {
    key: "coremenu.marketing.setup",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup")
    ),
  },
  {
    key: "coremenu.marketing.setup.acuanBungaInternal",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/acuanBungaInternal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/acuanBungaInternal")
    ),
  },
  {
    key: "coremenu.marketing.setup.generalSetup",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/generalSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/generalSetup")
    ),
  },

  {
    key: "coremenu.marketing.setup.productSetup",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/productSetup")
    ),
  },
  {
    key: "coremenu.marketing.setup.productSetup.product",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup/product`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/productSetup/product")
    ),
  },
  {
    key: "coremenu.marketing.setup.productSetup.product.formAddNew",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup/product/formAddNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/productSetup/product/AddNewProduct")
    ),
  },
  {
    key: "coremenu.marketing.setup.productSetup.product.formEdit",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup/product/formEdit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/productSetup/product/EditProduct")
    ),
  },
  {
    key: "coremenu.marketing.setup.productSetup.product.formAppv",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup/product/formAppv`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/productSetup/product/ProductApprovalList")
    ),
  },
  {
    key: "coremenu.marketing.setup.productSetup.productCabang",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup/productCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/productSetup/productCabang"
      )
    ),
  },
  {
    key: "coremenu.marketing.setup.productSetup.productOffering",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/productSetup/productOffering`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/productSetup/productOffering"
      )
    ),
  },

  {
    key: "coremenu.marketing.setup.assetSetup",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/assetSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/assetSetup")
    ),
  },
  {
    key: "coremenu.marketing.setup.assetSetup.jenisAsset",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/assetSetup/jenisAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/assetSetup/jenisAsset")
    ),
  },
  {
    key: "coremenu.marketing.setup.assetSetup.daftarAsset",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/assetSetup/daftarAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/assetSetup/daftarAsset")
    ),
  },

  {
    key: "coremenu.marketing.setup.assetSetup.daftarHargaOTR",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/assetSetup/daftarHargaOTR`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/assetSetup/daftarHargaOTR"
      )
    ),
  },

  {
    key: "coremenu.marketing.setup.assetSetup.kategoriAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/assetSetup/kategoriAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/assetSetup/kategoriAsuransi"
      )
    ),
  },

  {
    key: "coremenu.marketing.setup.itemScoringCard",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/itemScoringCard`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/itemScoringCard")
    ),
  },
  {
    key: "coremenu.marketing.setup.itemScoringCard.credit",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/itemScoringCard/credit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/itemScoringCard/credit")
    ),
  },

  {
    key: "coremenu.marketing.setup.scoringCardSetup",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/scoringCardSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/scoringCardSetup")
    ),
  },
  {
    key: "coremenu.marketing.setup.scoringCardSetup.credit",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/scoringCardSetup/credit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/scoringCardSetup/credit")
    ),
  },

  {
    key: "coremenu.marketing.setup.budgetandForecast",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/budgetandForecast`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/marketing/setup/budgetandForecast")
    ),
  },
  {
    key: "coremenu.marketing.setup.budgetandForecast.cmoInderectSales",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/budgetandForecast/cmoInderectSales`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/budgetandForecast/cmoInderectSales"
      )
    ),
  },
  {
    key: "coremenu.marketing.setup.budgetandForecast.cmoDirectSales",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/budgetandForecast/cmoDirectSales`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/budgetandForecast/cmoDirectSales"
      )
    ),
  },

  {
    key: "coremenu.marketing.setup.budgetandForecast.supervisor",
    path: `${APP_PREFIX_PATH}/coremenu/marketing/setup/budgetandForecast/supervisor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/marketing/setup/budgetandForecast/supervisor"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination")
    ),
  },
  {
    key: "coremenu.loanOrigination.customer",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customer`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/customer")
    ),
  },
  {
    key: "coremenu.loanOrigination.customerGroup",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerGroup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/customerGroup")
    ),
  },

  {
    key: "coremenu.loanOrigination.prospect",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prospect`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/prospect")
    ),
  },
  {
    key: "coremenu.loanOrigination.prospect",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prospect/form`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/prospect/FormProspect")
    ),
  },
  {
    key: "coremenu.loanOrigination.prospect.component.prospectAddNewTable",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prospect/component/prospectAddNewTable`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prospect/prospectAddNewTable"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.customerBermasalah",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerBermasalah`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/customerBermasalah")
    ),
  },
  {
    key: "coremenu.loanOrigination.importAplikasiEksternal",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/importAplikasiEksternal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/importAplikasiEksternal")
    ),
  },
  {
    key: "coremenu.loanOrigination.importAplikasiEksternal.uploadAplikasiDF",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/importAplikasiEksternal/uploadAplikasiDF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/importAplikasiEksternal/uploadAplikasiDF"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.importAplikasiEksternal.aktivasiAplikasiDF",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/importAplikasiEksternal/aktivasiAplikasiDF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/importAplikasiEksternal/aktivasiAplikasiDF"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.importAplikasiEksternal.requestPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/importAplikasiEksternal/requestPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/importAplikasiEksternal/requestPembayaran"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.importAplikasiEksternal.approvalPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/importAplikasiEksternal/approvalPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/importAplikasiEksternal/approvalPembayaran"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.importAplikasiEksternal.mappingDealer",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/importAplikasiEksternal/mappingDealer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/importAplikasiEksternal/mappingDealer"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.customerFacility",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerFacility`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/customerFacility")
    ),
  },
  {
    key: "coremenu.loanOrigination.customerFacility.addendumCustomerFacility",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerFacility/addendumCustomerFacility`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/customerFacility/addendumCustomerFacility"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.customerFacility.addendumCustomerFacilityApproval",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerFacility/addendumCustomerFacilityApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/customerFacility/addendumCustomerFacilityApproval"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.customerFacility.customerFacility",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerFacility/customerFacility`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/customerFacility/customerFacility"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.customerFacility.customerFacilityApproval",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/customerFacility/customerFacilityApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/customerFacility/customerFacilityApproval"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.prosesKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/prosesKredit")
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.aplikasiKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/aplikasiKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/aplikasiKredit"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.aplikasiKreditElektronik",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/aplikasiKreditElektronik`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/aplikasiKreditElektronik"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.penyimpangan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/penyimpangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/penyimpangan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.rabDanaTunai",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/rabDanaTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/rabDanaTunai"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.aplikasiReject",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/aplikasiReject`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/aplikasiReject"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.pengajuanApproval",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/pengajuanApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/pengajuanApproval"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.creditAssessment",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/creditAssessment`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/creditAssessment"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.approvalKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/approvalKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/approvalKredit"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.konfirmasiSupplier",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/konfirmasiSupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/konfirmasiSupplier"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.purchaseOrder",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/purchaseOrder`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/purchaseOrder"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.verifikasiPraPencairan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/verifikasiPraPencairan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/verifikasiPraPencairan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.aktivasiKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/aktivasiKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/aktivasiKredit"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.batalPencairan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/batalPencairan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/batalPencairan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.pembatalanKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/pembatalanKontrak`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/pembatalanKontrak"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.pembatalanAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/pembatalanAplikasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/pembatalanAplikasi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.koreksiAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/koreksiAplikasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/koreksiAplikasi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.koreksiAplikasiNonFinancial",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/koreksiAplikasiNonFinancial`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/koreksiAplikasiNonFinancial"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.simulasiAplikasiTDP",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/simulasiAplikasiTDP`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/simulasiAplikasiTDP"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.simulasiAplikasiAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/simulasiAplikasiAngsuran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/simulasiAplikasiAngsuran"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.simulasiAplikasiMSA",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/simulasiAplikasiMSA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/simulasiAplikasiMSA"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.perbaikanAplikasi.openAplikasiExpired",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/openAplikasiExpired`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/perbaikanAplikasi/openAplikasiExpired"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.prosesKredit.duplikasiAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/duplikasiAplikasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/duplikasiAplikasi"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.prosesKredit.kreditElektronik",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/kreditElektronik`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/kreditElektronik"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prosesKredit.kreditElektronik.poElektronik",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prosesKredit/kreditElektronik/poElektronik`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prosesKredit/kreditElektronik/poElektronik"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.trackingTVC",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/trackingTVC`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/trackingTVC")
    ),
  },
  {
    key: "coremenu.loanOrigination.dukcapil",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/dukcapil`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/dukcapil")
    ),
  },
  {
    key: "coremenu.loanOrigination.dukcapil.dataPenduduk",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/dukcapil/dataPenduduk`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/dukcapil/dataPenduduk")
    ),
  },

  {
    key: "coremenu.loanOrigination.cetakDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/cetakDokumen")
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.cetakDokumenKreditElektronik",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/cetakDokumenKreditElektronik`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/cetakDokumenKreditElektronik"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.daftarPemeriksaanAPK",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/daftarPemeriksaanAPK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/daftarPemeriksaanAPK"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratPersetujuanPembiayaan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratPersetujuanPembiayaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratPersetujuanPembiayaan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.ppkdph",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/ppkdph`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/cetakDokumen/ppkdph")
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.skPengikatanJaminanFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/skPengikatanJaminanFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/skPengikatanJaminanFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.pernyataanBedaTandaTangan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/pernyataanBedaTandaTangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/pernyataanBedaTandaTangan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.pernyataanNPWP",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/pernyataanNPWP`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/pernyataanNPWP"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratPernyataanBersama",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratPernyataanBersama`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratPernyataanBersama"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.kwitansi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/kwitansi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/cetakDokumen/kwitansi")
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.buktiPenyerahanKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/buktiPenyerahanKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/buktiPenyerahanKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.gesekanRangkaMesin",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/gesekanRangkaMesin`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/gesekanRangkaMesin"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratPermohonanTransfer",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratPermohonanTransfer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratPermohonanTransfer"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratPernyataanBPKBDalamProses",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratPernyataanBPKBDalamProses`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratPernyataanBPKBDalamProses"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.pemesananKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/pemesananKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/pemesananKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratPenolakan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratPenolakan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratPenolakan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.perhitunganHargadanDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/perhitunganHargadanDokumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/perhitunganHargadanDokumen"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.tandaTerimaDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/tandaTerimaDokumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/tandaTerimaDokumen"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.perjanjianPembiayaan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/perjanjianPembiayaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/perjanjianPembiayaan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumen.suratPemberitahuanVANewCustomer",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumen/suratPemberitahuanVANewCustomer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumen/suratPemberitahuanVANewCustomer"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai")
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai.kwitansiPencairanFasilitasDana",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai/kwitansiPencairanFasilitasDana`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai/kwitansiPencairanFasilitasDana"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai.suratKuasaPengikatanJaminanFidusia",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai/suratKuasaPengikatanJaminanFidusia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai/suratKuasaPengikatanJaminanFidusia"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai.suratPernyataanPenggunaanDana",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai/suratPernyataanPenggunaanDana`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai/suratPernyataanPenggunaanDana"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai.perjanjianPembiayaanFasilitasDana",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai/perjanjianPembiayaanFasilitasDana`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai/perjanjianPembiayaanFasilitasDana"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai.ilustrasiFasilitasDanaTunai",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai/ilustrasiFasilitasDanaTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai/ilustrasiFasilitasDanaTunai"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunai.suratPernyataanPemilikanKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunai/suratPernyataanPemilikanKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunai/suratPernyataanPemilikanKendaraan"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted.kwitansiPencairanFasilitasDana",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/kwitansiPencairanFasilitasDana`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/kwitansiPencairanFasilitasDana"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted.skPengikatanJaminanFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/skPengikatanJaminanFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/skPengikatanJaminanFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted.suratPernyataanPenggunaanDana",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/suratPernyataanPenggunaanDana`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/suratPernyataanPenggunaanDana"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted.perjanjianPembiayaanFasilitasDana",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/perjanjianPembiayaanFasilitasDana`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/perjanjianPembiayaanFasilitasDana"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted.ilustrasiFasilitasDanaTunai",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/ilustrasiFasilitasDanaTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/ilustrasiFasilitasDanaTunai"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.cetakDokumenDanaTunaiPrePrinted.suratPernyataanPemilikanKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/suratPernyataanPemilikanKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/cetakDokumenDanaTunaiPrePrinted/suratPernyataanPemilikanKendaraan"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW")
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.perjanjianPembiayaan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/perjanjianPembiayaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/perjanjianPembiayaan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.pengikatanJaminanFidusia",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/pengikatanJaminanFidusia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/pengikatanJaminanFidusia"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.suratPernyataandanKuasa",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/suratPernyataandanKuasa`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/suratPernyataandanKuasa"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.kuitansi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/kuitansi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/kuitansi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.ilustrasiFasilitasPembiayaan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/ilustrasiFasilitasPembiayaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/ilustrasiFasilitasPembiayaan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.perjanjianJaminan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/perjanjianJaminan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/perjanjianJaminan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.prePrintedUSEDdanNEW.pernyataanBersama",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/prePrintedUSEDdanNEW/pernyataanBersama`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/prePrintedUSEDdanNEW/pernyataanBersama"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.cetakAddendumRestruktur",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/cetakAddendumRestruktur`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/cetakAddendumRestruktur")
    ),
  },
  {
    key: "coremenu.loanOrigination.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/inquiry")
    ),
  },
  {
    key: "coremenu.loanOrigination.inquiry.statusAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/inquiry/statusAplikasi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/inquiry/statusAplikasi")
    ),
  },
  {
    key: "coremenu.loanOrigination.inquiry.kontrakBatal",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/inquiry/kontrakBatal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/inquiry/kontrakBatal")
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit.aplikasiKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit/aplikasiKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit/aplikasiKredit"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit.rekapAplikasiMasuk",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit/rekapAplikasiMasuk`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit/rekapAplikasiMasuk"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit.slaAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit/slaAplikasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit/slaAplikasi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit.hasilAplikasiMasuk",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit/hasilAplikasiMasuk`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit/hasilAplikasiMasuk"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit.rekapPencairanKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit/rekapPencairanKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit/rekapPencairanKredit"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiKredit.rekapPencairanKreditAP",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiKredit/rekapPencairanKreditAP`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiKredit/rekapPencairanKreditAP"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan.aplikasiPending",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiPending`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/aplikasiPending")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiPending.aplikasiPending",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiPending/aplikasiPending`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiPending/aplikasiPending"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiPending.monitoringAplikasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiPending/monitoringAplikasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiPending/monitoringAplikasi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.aplikasiPending.laporanFollowUpSPP",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/aplikasiPending/laporanFollowUpSPP`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/aplikasiPending/laporanFollowUpSPP"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/laporanLainnya")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.laporanAktivasiKredit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/laporanAktivasiKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/laporanAktivasiKredit"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.laporanAplikasiINampBacklog",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/laporanAplikasiINampBacklog`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/laporanAplikasiINampBacklog"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.agreementDownload",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/agreementDownload`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/agreementDownload"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.reportOM",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/reportOM`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/reportOM"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.spkdanPO",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/spkdanPO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/spkdanPO"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.tvcMonitoringBoard",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/tvcMonitoringBoard`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/tvcMonitoringBoard"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.laporanLainnya.laporanTransaksiMencurigakan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/laporanLainnya/laporanTransaksiMencurigakan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/laporanLainnya/laporanTransaksiMencurigakan"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan.creditScoring",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/creditScoring`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/creditScoring")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.creditScoring.detailCreditScoring",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/creditScoring/detailCreditScoring`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/creditScoring/detailCreditScoring"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.creditScoring.hasilCreditScoring",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/creditScoring/hasilCreditScoring`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/creditScoring/hasilCreditScoring"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan.exportDataBI",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportDataBI`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/exportDataBI")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.exportDataBI.dataBulanan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportDataBI/dataBulanan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/exportDataBI/dataBulanan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.exportDataBI.dataSemester",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportDataBI/dataSemester`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/exportDataBI/dataSemester"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan.penjualan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/penjualan")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaScores",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaScores`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaScores"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.produkNasionalTahunKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/produkNasionalTahunKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/produkNasionalTahunKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.produkPassengerdanCommercial",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/produkPassengerdanCommercial`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/produkPassengerdanCommercial"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaDownPayment",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaDownPayment`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaDownPayment"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaTenor",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaTenor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaTenor"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaKondisiKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaKondisiKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaKondisiKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaUsiaKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaUsiaKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaUsiaKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaMerkKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaMerkKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaMerkKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaPendapatanKonsumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaPendapatanKonsumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaPendapatanKonsumen"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaKategoriKendaraan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaKategoriKendaraan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaKategoriKendaraan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaNK"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaCommercialdanNonCommercial",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaCommercialdanNonCommercial`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaCommercialdanNonCommercial"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaPokokPembiayaan",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaPokokPembiayaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaPokokPembiayaan"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaAngsuran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaAngsuran"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaRatePerWilayah",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaRatePerWilayah`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaRatePerWilayah"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaKendaraanPerCabang",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaKendaraanPerCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaKendaraanPerCabang"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaKendaraanPerCabangNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaKendaraanPerCabangNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaKendaraanPerCabangNK"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaBYCMO",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaBYCMO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaBYCMO"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaBYDealer",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaBYDealer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaBYDealer"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.penjualan.analisaRatePerCabang",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/penjualan/analisaRatePerCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/penjualan/analisaRatePerCabang"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.laporan.exportOSS",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportOSS`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/laporan/exportOSS")
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.exportOSS.exportSPP",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportOSS/exportSPP`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/exportOSS/exportSPP"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.exportOSS.exportPO",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportOSS/exportPO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/exportOSS/exportPO"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.exportOSS.exportAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportOSS/exportAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/exportOSS/exportAsuransi"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.laporan.exportOSS.exportLunas",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/laporan/exportOSS/exportLunas`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/laporan/exportOSS/exportLunas"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.setup",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.generalSetup",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/generalSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/generalSetup")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.alokasiUlangCA",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/alokasiUlangCA`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/alokasiUlangCA")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.referralCategory",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/referralCategory`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/referralCategory")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.agreementReferral",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/agreementReferral`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/agreementReferral")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.aksesDataPersonalKonsumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/aksesDataPersonalKonsumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/aksesDataPersonalKonsumen"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.setup.product",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/product")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.productTC",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/ProductTC`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/product/ProductTC")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.productTC.formTC",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/productTC/formTC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/ProductTC/ProductTCForm"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.productDoc",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/productDoc`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/ProductDoc"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.productWorkFlow",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/productWorkFlow`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/ProductWorkFlow"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProduct",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/subProduct`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProduct"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProduct.addNewSubProduct",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProduct/AddNewSubProduct`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProduct/AddNewSubProduct"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProductoffering",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/subProductoffering`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOffering"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProductoffering.formAddNew",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/subProductoffering/formAddNew`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOffering/AddNewProduct"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProductoffering.formEdit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/subProductoffering/formEdit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOffering/SubProductOfferingForm"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProductOfferingDetail",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/subProductOfferingDetail`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingDetail"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProductOfferingDetail.formEdit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingDetail/formEdit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingDetail/EditSubProductOfferingDetail"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.subProductOfferingDetail.formAddNew",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingDetail/formAddNew`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingDetail/AddNewSubProductOfferingDetail"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOffering.AddNewProduct",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOffering/AddNewProduct`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOffering/AddNewProduct"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.component.formEdit",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/component/formEdit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/component/EditProduct"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.ProductDoc.form",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/ProductDoc/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/ProductDoc/FormEditProductDoc"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProduct.AddNewSubProduct",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProduct/AddNewSubProduct`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProduct/AddNewSubProduct"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOfferingFee.addNewProductFee",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingFee/AddNewProductFee`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingFee/AddNewProductFee"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOfferingFee.editProductFee",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingFee/editProductFee`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingFee/EditProductFee"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOfferingFee",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingFee`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingFee"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOfferingCollection",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingCollection"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOfferingCollection.addNewProductCollection",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/subProductOfferingCollection/AddNewProductCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingCollection/AddNewProductCollection"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.SubProductOfferingCollection.editProductCollection",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/SubProductOfferingCollection/editProductCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/SubProductOfferingCollection/EditProductCollection"
      )
    ),
  },

  {
    key: "coremenu.loanOrigination.setup.product.asset.financeAsset",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/Asset/financeAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/Asset/FinanceAsset"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.asset.collateralAsset",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/Asset/collateralAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/Asset/CollateralAsset"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanOrigination/setup/product")
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.component.productDoc",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/component/productDoc`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/component/ProductDoc"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.component.productWorkFlow",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/component/productWorkFlow`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/component/ProductWorkFlow"
      )
    ),
  },
  {
    key: "coremenu.loanOrigination.setup.product.component.subProduct",
    path: `${APP_PREFIX_PATH}/coremenu/loanOrigination/setup/product/component/subProduct`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanOrigination/setup/product/component/SubProduct"
      )
    ),
  },

  {
    key: "coremenu.cashMgt",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt`,
    component: React.lazy(() => import("views/app-views/coremenu/cashMgt")),
  },

  {
    key: "coremenu.cashMgt.pembayaran.",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.terimaTagihan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/terimaTagihan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/terimaTagihan")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.gantiCaraBayarPV",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/gantiCaraBayarPV`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/gantiCaraBayarPV")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.gantiCaraBayarTRF",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/gantiCaraBayarTRF`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/gantiCaraBayarTRF")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.tagihanPencairanDealer",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/tagihanPencairanDealer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/tagihanPencairanDealer"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.pembayaranLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/pembayaranLainnya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/pembayaranLainnya")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.verifikasiPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/verifikasiPembayaran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/verifikasiPembayaran")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.approvalPembayaranBM",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/approvalPembayaranBM`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/approvalPembayaranBM")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.verifikasiFinance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/verifikasiFinance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/verifikasiFinance")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.pengaturanPembayaranLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/pengaturanPembayaranLainnya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/pengaturanPembayaranLainnya"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.persiapanDataEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/persiapanDataEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/persiapanDataEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.releaseTransaksiEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/releaseTransaksiEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/releaseTransaksiEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.konfirmasiTransaksiEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/konfirmasiTransaksiEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/konfirmasiTransaksiEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.eksekusiTransaksiEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/eksekusiTransaksiEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/eksekusiTransaksiEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.tolakanNonPaymentVoucher",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/tolakanNonPaymentVoucher`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/tolakanNonPaymentVoucher"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.tolakanTransaksiEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/tolakanTransaksiEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/tolakanTransaksiEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.koreksiDataEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/koreksiDataEBanking`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/koreksiDataEBanking")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.transaksiEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/transaksiEBanking`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/transaksiEBanking")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.transaksiEBanking.permintaanPendingEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/transaksiEBanking/permintaanPendingEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/transaksiEBanking/permintaanPendingEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.prosesPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/prosesPembayaran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/prosesPembayaran")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.prosesPembayaran.pembayaranTunai",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/prosesPembayaran/pembayaranTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/prosesPembayaran/pembayaranTunai"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.prosesPembayaran.pembayaranBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/prosesPembayaran/pembayaranBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/prosesPembayaran/pembayaranBank"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.permintaanKoreksiDataEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/permintaanKoreksiDataEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/permintaanKoreksiDataEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.inquiry.",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/inquiry")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.inquiry.apInquiry",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/inquiry/apInquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/inquiry/apInquiry")
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.inquiry.voucherPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/inquiry/voucherPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/inquiry/voucherPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.approvalKoreksiDataEBanking",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/approvalKoreksiDataEBanking`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/approvalKoreksiDataEBanking"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.tagihanBiayaCollection",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/tagihanBiayaCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/pembayaran/tagihanBiayaCollection"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.pembayaran.gantiLokasiBayar",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/pembayaran/gantiLokasiBayar`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/pembayaran/gantiLokasiBayar")
    ),
  },

  {
    key: "coremenu.cashMgt.kasirAR",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranTunai",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranTunai`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranTunai")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranTunaiPrioritas",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranTunaiPrioritas`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranTunaiPrioritas"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.advanceReceiveCustomerTunai",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/advanceReceiveCustomerTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/advanceReceiveCustomerTunai"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranViaBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaBank"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranViaBankPrioritas",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaBankPrioritas`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaBankPrioritas"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranViaEDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaEDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaEDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanViaMobileCollection",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanViaMobileCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanViaMobileCollection"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.alokasiAdvanceReceiveCustomer",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/alokasiAdvanceReceiveCustomer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/alokasiAdvanceReceiveCustomer"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.koreksiPenerimaanAngsuranViaBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/koreksiPenerimaanAngsuranViaBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/koreksiPenerimaanAngsuranViaBank"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranViaCollection",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaCollection"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.penerimaanAngsuranViaCollectionPrioritas",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaCollectionPrioritas`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/penerimaanAngsuranViaCollectionPrioritas"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.printPaymentReceive",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/printPaymentReceive`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/printPaymentReceive")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.koreksiAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/koreksiAngsuran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/koreksiAngsuran")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.otorisasiTransaksi",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/otorisasiTransaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/otorisasiTransaksi")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.requestReversalPenerimaan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/requestReversalPenerimaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/requestReversalPenerimaan"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.reversalPenerimaan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/reversalPenerimaan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/reversalPenerimaan")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/virtualAccount")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount.registrasiVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount/registrasiVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/virtualAccount/registrasiVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount.generateAngsuranVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount/generateAngsuranVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/virtualAccount/generateAngsuranVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount.uploadRekeningKoran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount/uploadRekeningKoran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/virtualAccount/uploadRekeningKoran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount.uploadVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount/uploadVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/virtualAccount/uploadVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount.otorisasiVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount/otorisasiVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/virtualAccount/otorisasiVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.virtualAccount.rekonBankVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/virtualAccount/rekonBankVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/virtualAccount/rekonBankVirtualAccount"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasirAR.billerStatement",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/billerStatement`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/billerStatement")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.billerStatement.uploadBillerStatementMST",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/billerStatement/uploadBillerStatementMST`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/billerStatement/uploadBillerStatementMST"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.billerStatement.postBillerStatementMST",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/billerStatement/postBillerStatementMST`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/billerStatement/postBillerStatementMST"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.billerStatement.laporanBillerStatement",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/billerStatement/laporanBillerStatement`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasirAR/billerStatement/laporanBillerStatement"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasirAR.uploadPotongGaji",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/uploadPotongGaji`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/uploadPotongGaji")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.rekonPotongGaji",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/rekonPotongGaji`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/rekonPotongGaji")
    ),
  },
  {
    key: "coremenu.cashMgt.kasirAR.otorisasiPotongGaji",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasirAR/otorisasiPotongGaji`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasirAR/otorisasiPotongGaji")
    ),
  },

  {
    key: "coremenu.cashMgt.kasir",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.cabangReadyEOD",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/cabangReadyEOD`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/cabangReadyEOD")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.saldoRekeningKasBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/saldoRekeningKasBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/saldoRekeningKasBank")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.bukaTutupKasir",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/bukaTutupKasir`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/bukaTutupKasir")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.otorisasiRekeningKoran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/otorisasiRekeningKoran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/otorisasiRekeningKoran")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.prosesPreEOD",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/prosesPreEOD`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/prosesPreEOD")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.rekeningKoran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/rekeningKoran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/rekeningKoran")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.terimaPDCReceive",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/terimaPDCReceive`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/terimaPDCReceive"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.terimaPDCMultiTransaction",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/terimaPDCMultiTransaction`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/terimaPDCMultiTransaction"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.terimaPDCMultiKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/terimaPDCMultiKontrak`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/terimaPDCMultiKontrak"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.setorPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/setorPDC`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC/setorPDC")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.kliringPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/kliringPDC`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC/kliringPDC")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.pdcCair",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/pdcCair`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC/pdcCair")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.pdcDitolak",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/pdcDitolak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC/pdcDitolak")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporanOutstandingPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporanOutstandingPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporanOutstandingPDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.additionalProcess",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/additionalProcess`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/additionalProcess"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.additionalProcess.gantiStatusPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/additionalProcess/gantiStatusPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/additionalProcess/gantiStatusPDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.additionalProcess.koreksiPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/additionalProcess/koreksiPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/additionalProcess/koreksiPDC"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC/inquiry")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.inquiry.terimaPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/inquiry/terimaPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/inquiry/terimaPDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.inquiry.statusPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/inquiry/statusPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/inquiry/statusPDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.inquiry.pdcTidakLengkap",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/inquiry/pdcTidakLengkap`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/inquiry/pdcTidakLengkap"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporan")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporan.pdcJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporan/pdcJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporan/pdcJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporan.setoranPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporan/setoranPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporan/setoranPDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporan.pdcPosting",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporan/pdcPosting`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporan/pdcPosting"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporan.penerimaanPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporan/penerimaanPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporan/penerimaanPDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiPDC.laporan.outstandingPDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiPDC/laporan/outstandingPDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiPDC/laporan/outstandingPDC"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiSuspend")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.suspendImplementasi",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/suspendImplementasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/suspendImplementasi"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.terimaSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/terimaSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/terimaSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.otorisasiSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/otorisasiSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/otorisasiSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.reversalSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/reversalSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/reversalSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.splitSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/splitSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/splitSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.alokasiSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/alokasiSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/alokasiSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.allocationSuspendUmum",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/allocationSuspendUmum`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/allocationSuspendUmum"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/inquiry")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/laporan")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.laporan.transaksiSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/laporan/transaksiSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/laporan/transaksiSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.laporan.outstandingSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/laporan/outstandingSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/laporan/outstandingSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.laporan.alokasiSuspend",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/laporan/alokasiSuspend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/laporan/alokasiSuspend"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiSuspend.laporan.periksaSuspendUmum",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiSuspend/laporan/periksaSuspendUmum`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiSuspend/laporan/periksaSuspendUmum"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.pettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/pettyCash")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction.advanceRequest",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/advanceRequest`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/advanceRequest"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction.advanceReturn",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/advanceReturn`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/advanceReturn"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction.advanceInquiry",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/advanceInquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/advanceInquiry"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction.report",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/report`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/report"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction.report.pcAdvanceTransaction",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/report/pcAdvanceTransaction`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/report/pcAdvanceTransaction"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.advanceTransaction.report.osPCAdvanceTransaction",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/report/osPCAdvanceTransaction`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/advanceTransaction/report/osPCAdvanceTransaction"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash.transaksiPettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/transaksiPettyCash`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/transaksiPettyCash"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash.reversalPettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/reversalPettyCash`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/reversalPettyCash"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash.inquiryPettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/inquiryPettyCash`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/inquiryPettyCash"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/laporan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/laporan"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash.laporan.pettyCashSummary",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/laporan/pettyCashSummary`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/laporan/pettyCashSummary"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.transaksiPettyCash.laporan.pettyCashDetail",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/laporan/pettyCashDetail`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/transaksiPettyCash/laporan/pettyCashDetail"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.pettyCash.reimbursePettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.reimbursePettyCash.pengajuanPettyCashReimburse",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/pengajuanPettyCashReimburse`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/pengajuanPettyCashReimburse"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.reimbursePettyCash.approvalReimbursePettyCash",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/approvalReimbursePettyCash`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/approvalReimbursePettyCash"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.reimbursePettyCash.terimaPettyCashReimburse",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/terimaPettyCashReimburse`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/terimaPettyCashReimburse"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.reimbursePettyCash.inquiryPettyCashReimburse",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/inquiryPettyCashReimburse`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/inquiryPettyCashReimburse"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.pettyCash.reimbursePettyCash.laporanPettyCashReimburse",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/laporanPettyCashReimburse`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/pettyCash/reimbursePettyCash/laporanPettyCashReimburse"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.permintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/permintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/permintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.koreksiPermintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/koreksiPermintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/koreksiPermintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.approvalPermintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/approvalPermintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/approvalPermintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.terimaPermintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/terimaPermintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/terimaPermintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.eksekusiPermintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/eksekusiPermintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/eksekusiPermintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.inquiryPermintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/inquiryPermintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/inquiryPermintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.laporanPPDRORequest",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/laporanPPDRORequest`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/laporanPPDRORequest"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanDanaOPS.laporanPPDROPaid",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanDanaOPS/laporanPPDROPaid`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanDanaOPS/laporanPPDROPaid"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.permintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanPembayaran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/permintaanPembayaran")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanPembayaran.permintaanPembayaranVendor",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanPembayaran/permintaanPembayaranVendor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanPembayaran/permintaanPembayaranVendor"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.permintaanPembayaran.laporanPembayaranVendor",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/permintaanPembayaran/laporanPembayaranVendor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/permintaanPembayaran/laporanPembayaranVendor"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/transaksiLainnya")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.revisiPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/revisiPenjualanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/revisiPenjualanAsset"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.terimaUangPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/terimaUangPenjualanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/terimaUangPenjualanAsset"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.eksekusiHasilJualAsset",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/eksekusiHasilJualAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/eksekusiHasilJualAsset"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.otorisasiWanpresRepoSale",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/otorisasiWanpresRepoSale`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/otorisasiWanpresRepoSale"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.wanpresRepoSale",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/wanpresRepoSale`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/wanpresRepoSale"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.terimaKlaimAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/terimaKlaimAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/terimaKlaimAsuransi"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.penerimaanLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/penerimaanLainnya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/penerimaanLainnya"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.pembayaranLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/pembayaranLainnya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/pembayaranLainnya"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.rekonsiliasiTransaksiBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/rekonsiliasiTransaksiBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/rekonsiliasiTransaksiBank"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.pembayarandanPenerimaanLainnyaUpload",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.pembayarandanPenerimaanLainnyaUpload.upload",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload/upload`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload/upload"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.pembayarandanPenerimaanLainnyaUpload.otorisasi",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload/otorisasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload/otorisasi"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.pembayarandanPenerimaanLainnyaUpload.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/pembayarandanPenerimaanLainnyaUpload/inquiry"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.terimaHasilTransfer",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/terimaHasilTransfer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/terimaHasilTransfer"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.bilyetGiroMaintenance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/bilyetGiroMaintenance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/bilyetGiroMaintenance"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.bankMultiEntri",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/bankMultiEntri`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/bankMultiEntri"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.transaksiLainnya.terimaKlaimAsuransiHO",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/transaksiLainnya/terimaKlaimAsuransiHO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/transaksiLainnya/terimaKlaimAsuransiHO"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.advanceRegional",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/advanceRegional")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.requestAdvance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/requestAdvance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/advanceRegional/requestAdvance"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.inquiryAdvance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/inquiryAdvance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/advanceRegional/inquiryAdvance"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.advanceReturn",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/advanceReturn`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/advanceRegional/advanceReturn"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/advanceRegional/laporan")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.monitoringAll",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/monitoringAll`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/advanceRegional/monitoringAll"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.laporanAdvanceRegionalOS",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/laporanAdvanceRegionalOS`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/advanceRegional/laporanAdvanceRegionalOS"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.advanceRegional.laporanAdvanceRegionalReturned",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/advanceRegional/laporanAdvanceRegionalReturned`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/advanceRegional/laporanAdvanceRegionalReturned"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/inquiry")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.inquiry.voucherTunaiBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/inquiry/voucherTunaiBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/inquiry/voucherTunaiBank")
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarPenerimaanTunai",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPenerimaanTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPenerimaanTunai"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarMutasiTunai",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiTunai"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarPenerimaanViaCollector",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPenerimaanViaCollector`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPenerimaanViaCollector"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarPenerimaanBedaCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPenerimaanBedaCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPenerimaanBedaCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarPemeriksaanTransaksiBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPemeriksaanTransaksiBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarPemeriksaanTransaksiBank"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarMutasiBank",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiBank"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarMutasiBankEDC",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiBankEDC`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiBankEDC"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarMutasiBankKolektor",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiBankKolektor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiBankKolektor"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanTerimaAngsuranPerKolektor",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanTerimaAngsuranPerKolektor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanTerimaAngsuranPerKolektor"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.detailAlokasiPenerimaan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/detailAlokasiPenerimaan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/detailAlokasiPenerimaan"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanPembayaranBedaCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanPembayaranBedaCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanPembayaranBedaCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanCashIn",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanCashIn`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanCashIn"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanCashInAllocation",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanCashInAllocation`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanCashInAllocation"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.proyeksiPenerimaanAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/proyeksiPenerimaanAngsuran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/proyeksiPenerimaanAngsuran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarMutasiRekeningVA",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiRekeningVA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarMutasiRekeningVA"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarTransaksiVA",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarTransaksiVA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarTransaksiVA"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanKoreksiAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanKoreksiAngsuran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanKoreksiAngsuran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarAngsuranDFS",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarAngsuranDFS`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarAngsuranDFS"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarOtorisasiVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarOtorisasiVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarOtorisasiVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanCekUploadVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanCekUploadVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanCekUploadVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.daftarRekonHOVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarRekonHOVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/daftarRekonHOVirtualAccount"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.detailAlokasiPembVA",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/detailAlokasiPembVA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/detailAlokasiPembVA"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.laporanVerifikasiVA",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanVerifikasiVA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/laporanVerifikasiVA"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.laporanTunaidanBank.detailPembayaranIndomaret",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanTunaidanBank/detailPembayaranIndomaret`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/laporanTunaidanBank/detailPembayaranIndomaret"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.integrityCheckPerKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/integrityCheckPerKontrak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/integrityCheckPerKontrak")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.cashAdvanced",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/cashAdvanced`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/cashAdvanced")
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.cashAdvanced.pengajuanCashAdvance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/cashAdvanced/pengajuanCashAdvance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/cashAdvanced/pengajuanCashAdvance"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.cashAdvanced.approvalPengajuanCashAdvance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/cashAdvanced/approvalPengajuanCashAdvance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/cashAdvanced/approvalPengajuanCashAdvance"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.kasir.cashAdvanced.realisasiPengajuanCashAdvance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/cashAdvanced/realisasiPengajuanCashAdvance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/kasir/cashAdvanced/realisasiPengajuanCashAdvance"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.kasir.laporanRekeningKoran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/kasir/laporanRekeningKoran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/kasir/laporanRekeningKoran")
    ),
  },

  {
    key: "coremenu.cashMgt.transferUang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/transferUang")
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.transferAntarRekening",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/transferAntarRekening`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/transferAntarRekening"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.otorisasiTransferAntarRekening",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/otorisasiTransferAntarRekening`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/otorisasiTransferAntarRekening"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.transferAntarRekeningUpload",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/transferAntarRekeningUpload`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/transferAntarRekeningUpload"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.otorisasiTransferAntarRekeningUpload",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/otorisasiTransferAntarRekeningUpload`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/otorisasiTransferAntarRekeningUpload"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.koreksiTransferAntarRekening",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/koreksiTransferAntarRekening`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/koreksiTransferAntarRekening"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.penarikanDanaCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/penarikanDanaCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/penarikanDanaCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.otorisasiPenarikanDanaCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/otorisasiPenarikanDanaCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/otorisasiPenarikanDanaCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.transferDanaCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/transferDanaCabang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/transferUang/transferDanaCabang")
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.otorisasiTransferDanaCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/otorisasiTransferDanaCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/otorisasiTransferDanaCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.reversalTransferDanaAntarCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/reversalTransferDanaAntarCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/reversalTransferDanaAntarCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.otorisasiReversalTransferDanaAntarCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/otorisasiReversalTransferDanaAntarCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/otorisasiReversalTransferDanaAntarCabang"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.transferPettyCashReimburse",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/transferPettyCashReimburse`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/transferPettyCashReimburse"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.transferPermintaanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/transferPermintaanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/transferPermintaanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.inquiryTransaksiTransfer",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/inquiryTransaksiTransfer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/inquiryTransaksiTransfer"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.transferUang.inquiryTransferDanaAntarCabang",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/transferUang/inquiryTransferDanaAntarCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/transferUang/inquiryTransferDanaAntarCabang"
      )
    ),
  },

  {
    key: "coremenu.cashMgt.laporanDisbursement",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/cashMgt/laporanDisbursement")
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rekapRencanaPembayaranDealer",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rekapRencanaPembayaranDealer`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rekapRencanaPembayaranDealer"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rekapRencanaBayarLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rekapRencanaBayarLainnya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rekapRencanaBayarLainnya"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rangkumanPengajuanPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rangkumanPengajuanPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rangkumanPengajuanPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rangkumanPengajuanPembayaranBiaya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rangkumanPengajuanPembayaranBiaya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rangkumanPengajuanPembayaranBiaya"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rekapRealisasiPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rekapRealisasiPembayaran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rekapRealisasiPembayaran"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.laporanInsentifdanPajak",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/laporanInsentifdanPajak`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/laporanInsentifdanPajak"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.pembayaranPPh",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/pembayaranPPh`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/pembayaranPPh"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rekapPembayaranPPh",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rekapPembayaranPPh`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rekapPembayaranPPh"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.pphOutstanding",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/pphOutstanding`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/pphOutstanding"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.paymentVoucherPencairan",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/paymentVoucherPencairan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/paymentVoucherPencairan"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rekapPencairanDFS",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rekapPencairanDFS`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rekapPencairanDFS"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.timeDisburseSupplier",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/timeDisburseSupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/timeDisburseSupplier"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.timeDisburseInsurance",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/timeDisburseInsurance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/timeDisburseInsurance"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.laporanPembayaranLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/laporanPembayaranLainnya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/laporanPembayaranLainnya"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.historyPenerimaanInsentif",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/historyPenerimaanInsentif`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/historyPenerimaanInsentif"
      )
    ),
  },
  {
    key: "coremenu.cashMgt.laporanDisbursement.rekapPembayaranBiaya",
    path: `${APP_PREFIX_PATH}/coremenu/cashMgt/laporanDisbursement/rekapPembayaranBiaya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/cashMgt/laporanDisbursement/rekapPembayaranBiaya"
      )
    ),
  },

  {
    key: "coremenu.funding",
    path: `${APP_PREFIX_PATH}/coremenu/funding`,
    component: React.lazy(() => import("views/app-views/coremenu/funding")),
  },
  {
    key: "coremenu.funding.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi")
    ),
  },
  {
    key: "coremenu.funding.transaksi.sumberPendanaan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/sumberPendanaan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/sumberPendanaan")
    ),
  },
  {
    key: "coremenu.funding.transaksi.approvalPencairanPinjaman",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/approvalPencairanPinjaman`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/approvalPencairanPinjaman"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.pencairanPinjaman",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/pencairanPinjaman`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/pencairanPinjaman")
    ),
  },
  {
    key: "coremenu.funding.transaksi.bayarAngsuranBatchPerJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/bayarAngsuranBatchPerJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/bayarAngsuranBatchPerJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.pelunasanPerKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/pelunasanPerKontrak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/pelunasanPerKontrak")
    ),
  },
  {
    key: "coremenu.funding.transaksi.pelunasanUpload",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/pelunasanUpload`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/pelunasanUpload")
    ),
  },
  {
    key: "coremenu.funding.transaksi.approvalPelunasanPerKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/approvalPelunasanPerKontrak`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/approvalPelunasanPerKontrak"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.bayarAngsuranPerJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/bayarAngsuranPerJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/bayarAngsuranPerJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.bayarAngsuranPerJatuhTempoUpload",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/bayarAngsuranPerJatuhTempoUpload`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/bayarAngsuranPerJatuhTempoUpload"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.updateAmortisasiBatch",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/updateAmortisasiBatch`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/updateAmortisasiBatch")
    ),
  },
  {
    key: "coremenu.funding.transaksi.pembayaranAngsuranMirroring",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/pembayaranAngsuranMirroring`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/pembayaranAngsuranMirroring"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.downloadCustomerPaymentFile",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/downloadCustomerPaymentFile`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/downloadCustomerPaymentFile"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.reversalAngsuranTermLoan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/reversalAngsuranTermLoan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/reversalAngsuranTermLoan"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.reversalPelunasan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/reversalPelunasan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/reversalPelunasan")
    ),
  },
  {
    key: "coremenu.funding.transaksi.reversalPembayaranAngsuranJF",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/reversalPembayaranAngsuranJF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/reversalPembayaranAngsuranJF"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.penarikanJaminanTermloan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/penarikanJaminanTermloan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/penarikanJaminanTermloan"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.penarikanJaminanTermloanApproval",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/penarikanJaminanTermloanApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/penarikanJaminanTermloanApproval"
      )
    ),
  },
  {
    key: "coremenu.funding.transaksi.uploadPembAngsuranTL",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/uploadPembAngsuranTL`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/transaksi/uploadPembAngsuranTL")
    ),
  },
  {
    key: "coremenu.funding.transaksi.uploadPembAngsuranTLApproval",
    path: `${APP_PREFIX_PATH}/coremenu/funding/transaksi/uploadPembAngsuranTLApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/transaksi/uploadPembAngsuranTLApproval"
      )
    ),
  },

  {
    key: "coremenu.funding.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry")
    ),
  },
  {
    key: "coremenu.funding.inquiry.pelunasanKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/pelunasanKontrak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/pelunasanKontrak")
    ),
  },
  {
    key: "coremenu.funding.inquiry.pencairanTanpaJaminan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/pencairanTanpaJaminan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/pencairanTanpaJaminan")
    ),
  },
  {
    key: "coremenu.funding.inquiry.outstandingPinjaman",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/outstandingPinjaman`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/outstandingPinjaman")
    ),
  },
  {
    key: "coremenu.funding.inquiry.fasilitasAkanJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/fasilitasAkanJatuhTempo`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/fasilitasAkanJatuhTempo")
    ),
  },
  {
    key: "coremenu.funding.inquiry.jaminanAkanSelesai",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/jaminanAkanSelesai`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/jaminanAkanSelesai")
    ),
  },
  {
    key: "coremenu.funding.inquiry.angsuranJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/angsuranJatuhTempo`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/angsuranJatuhTempo")
    ),
  },
  {
    key: "coremenu.funding.inquiry.accruedBunga",
    path: `${APP_PREFIX_PATH}/coremenu/funding/inquiry/accruedBunga`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/inquiry/accruedBunga")
    ),
  },

  {
    key: "coremenu.funding.cetakDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/funding/cetakDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/cetakDokumen")
    ),
  },
  {
    key: "coremenu.funding.cetakDokumen.suratPelunasanFunding",
    path: `${APP_PREFIX_PATH}/coremenu/funding/cetakDokumen/suratPelunasanFunding`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/cetakDokumen/suratPelunasanFunding"
      )
    ),
  },
  {
    key: "coremenu.funding.cetakDokumen.perhitunganPelunasan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/cetakDokumen/perhitunganPelunasan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/cetakDokumen/perhitunganPelunasan"
      )
    ),
  },

  {
    key: "coremenu.funding.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan")
    ),
  },
  {
    key: "coremenu.funding.laporan.laporanPinjamanBank",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/laporanPinjamanBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/laporanPinjamanBank")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAngsuranBankPerBatch",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAngsuranBankPerBatch`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarAngsuranBankPerBatch"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAngsuranBankPerKonsumen",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAngsuranBankPerKonsumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarAngsuranBankPerKonsumen"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.rincianAngsuranBank",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/rincianAngsuranBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/rincianAngsuranBank")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarDefinitifDebitur",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarDefinitifDebitur`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/daftarDefinitifDebitur")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAngsuranPerJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAngsuranPerJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarAngsuranPerJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAngsuranBankPerBatchJF",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAngsuranBankPerBatchJF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarAngsuranBankPerBatchJF"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAngsuranBankPerBatchTL",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAngsuranBankPerBatchTL`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarAngsuranBankPerBatchTL"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAmortisasiBankdanKonsumen",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAmortisasiBankdanKonsumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarAmortisasiBankdanKonsumen"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.jadwalAngsuranPerTanggalJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/jadwalAngsuranPerTanggalJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/jadwalAngsuranPerTanggalJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.jadwalAngsuranPerTanggalJTSummary",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/jadwalAngsuranPerTanggalJTSummary`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/jadwalAngsuranPerTanggalJTSummary"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarPembayaranAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarPembayaranAngsuran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarPembayaranAngsuran"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarPelunasan",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarPelunasan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/daftarPelunasan")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarFasilitasTersedia",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarFasilitasTersedia`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/daftarFasilitasTersedia")
    ),
  },
  {
    key: "coremenu.funding.laporan.cashProjection",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/cashProjection`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/cashProjection")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarPembayaranAngsuranSummary",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarPembayaranAngsuranSummary`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarPembayaranAngsuranSummary"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarPembayaranBank",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarPembayaranBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/daftarPembayaranBank")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarPencairanBank",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarPencairanBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/daftarPencairanBank")
    ),
  },
  {
    key: "coremenu.funding.laporan.rekapPenarikanFunding",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/rekapPenarikanFunding`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/rekapPenarikanFunding")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarCustomerOverdueFunding",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarCustomerOverdueFunding`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/daftarCustomerOverdueFunding"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.laporanPembAngsuranAdvanceTAF",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/laporanPembAngsuranAdvanceTAF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporan/laporanPembAngsuranAdvanceTAF"
      )
    ),
  },
  {
    key: "coremenu.funding.laporan.laporanPenarikanKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/laporanPenarikanKontrak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/laporanPenarikanKontrak")
    ),
  },
  {
    key: "coremenu.funding.laporan.daftarAmortisasiJF",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporan/daftarAmortisasiJF`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporan/daftarAmortisasiJF")
    ),
  },

  {
    key: "coremenu.funding.setup",
    path: `${APP_PREFIX_PATH}/coremenu/funding/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/setup")
    ),
  },
  {
    key: "coremenu.funding.setup.templateFundingKriteria",
    path: `${APP_PREFIX_PATH}/coremenu/funding/setup/templateFundingKriteria`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/setup/templateFundingKriteria")
    ),
  },

  {
    key: "coremenu.funding.laporanNew",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporanNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/funding/laporanNew")
    ),
  },
  {
    key: "coremenu.funding.laporanNew.angsuranJTKonsumenBayar",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporanNew/angsuranJTKonsumenBayar`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporanNew/angsuranJTKonsumenBayar"
      )
    ),
  },
  {
    key: "coremenu.funding.laporanNew.angsuranJFKonsBelumBayar",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporanNew/angsuranJFKonsBelumBayar`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporanNew/angsuranJFKonsBelumBayar"
      )
    ),
  },
  {
    key: "coremenu.funding.laporanNew.angsuranJFKonsBayarAdvance",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporanNew/angsuranJFKonsBayarAdvance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporanNew/angsuranJFKonsBayarAdvance"
      )
    ),
  },
  {
    key: "coremenu.funding.laporanNew.settlementAngsuranviaTAF",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporanNew/settlementAngsuranviaTAF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporanNew/settlementAngsuranviaTAF"
      )
    ),
  },
  {
    key: "coremenu.funding.laporanNew.konsumenBayarAngsuranJF",
    path: `${APP_PREFIX_PATH}/coremenu/funding/laporanNew/konsumenBayarAngsuranJF`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/funding/laporanNew/konsumenBayarAngsuranJF"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance")
    ),
  },
  {
    key: "coremenu.loanMaintenance.pembebasanBiayadanDenda",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pembebasanBiayadanDenda`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/pembebasanBiayadanDenda")
    ),
  },
  {
    key: "coremenu.loanMaintenance.pembebasanBiayadanDenda.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pembebasanBiayadanDenda/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/pembebasanBiayadanDenda/inquiry"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.pembebasanBiayadanDenda.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pembebasanBiayadanDenda/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/pembebasanBiayadanDenda/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.pembebasanBiayadanDenda.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pembebasanBiayadanDenda/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/pembebasanBiayadanDenda/pengajuan"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/perubahan")
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/perubahan/pelunasan")
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pelunasan/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan.pengajuanOnline",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan/pengajuanOnline`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pelunasan/pengajuanOnline"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pelunasan/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan.eksekusi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan/eksekusi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pelunasan/eksekusi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pelunasan/inquiry"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pelunasan.simulasiPelunasan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pelunasan/simulasiPelunasan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pelunasan/simulasiPelunasan"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.pengajuanGantiTglJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/pengajuanGantiTglJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/pengajuanGantiTglJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.relaksasi6Bulan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/relaksasi6Bulan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/relaksasi6Bulan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.pisahBungaPokok",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/pisahBungaPokok`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/pisahBungaPokok"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.penundaanAngsuran",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/penundaanAngsuran`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/penundaanAngsuran"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.relaksasi3Bulan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/relaksasi3Bulan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/relaksasi3Bulan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.eksekusiGracePeriod",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/eksekusiGracePeriod`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/eksekusiGracePeriod"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.perpanjanganRelaksasi3Bulan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/perpanjanganRelaksasi3Bulan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/perpanjanganRelaksasi3Bulan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.perpanjanganRelaksasi6Bulan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/perpanjanganRelaksasi6Bulan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/perpanjanganRelaksasi6Bulan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.uploadDaftarRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/uploadDaftarRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/uploadDaftarRelaksasi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.bukaBlokirDaftarRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/bukaBlokirDaftarRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/bukaBlokirDaftarRelaksasi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/inquiry"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.view",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/view`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/view"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi3Bulan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi3Bulan.amandemenPembMultigunaFDIndividu",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/amandemenPembMultigunaFDIndividu`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/amandemenPembMultigunaFDIndividu"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi3Bulan.amandemenPembMultigunaMGIndividu",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/amandemenPembMultigunaMGIndividu`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/amandemenPembMultigunaMGIndividu"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi3Bulan.amandemenPembMultigunaMGBadanUsaha",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/amandemenPembMultigunaMGBadanUsaha`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/amandemenPembMultigunaMGBadanUsaha"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi3Bulan.lampRestrukturisasiPemberitahuanPersetujuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/lampRestrukturisasiPemberitahuanPersetujuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi3Bulan/lampRestrukturisasiPemberitahuanPersetujuan"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi6Bulan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi6Bulan.amandemenPembMultigunaFDIndividu",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/amandemenPembMultigunaFDIndividu`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/amandemenPembMultigunaFDIndividu"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi6Bulan.amandemenPembMultigunaMGIndividu",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/amandemenPembMultigunaMGIndividu`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/amandemenPembMultigunaMGIndividu"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi6Bulan.amandemenPembMultigunaMGBadanUsaha",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/amandemenPembMultigunaMGBadanUsaha`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/amandemenPembMultigunaMGBadanUsaha"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenRelaksasi6Bulan.suratPersetujuanRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/suratPersetujuanRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenRelaksasi6Bulan/suratPersetujuanRelaksasi"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenPerpanjanganRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenPerpanjanganRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenPerpanjanganRelaksasi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenPerpanjanganRelaksasi.amandemenPerpanjanganIndividu",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenPerpanjanganRelaksasi/amandemenPerpanjanganIndividu`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenPerpanjanganRelaksasi/amandemenPerpanjanganIndividu"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.cetakDokumenPerpanjanganRelaksasi.amandemenPerpanjanganBadanUsaha",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenPerpanjanganRelaksasi/amandemenPerpanjanganBadanUsaha`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/cetakDokumenPerpanjanganRelaksasi/amandemenPerpanjanganBadanUsaha"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.deviasiRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/deviasiRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/deviasiRelaksasi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.approvalDeviasiRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/approvalDeviasiRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/approvalDeviasiRelaksasi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.gantiTglJatuhTempo.inquiryDeviasiRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/inquiryDeviasiRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiTglJatuhTempo/inquiryDeviasiRelaksasi"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.rescheduling",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/rescheduling`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/perubahan/rescheduling")
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.rescheduling.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/rescheduling/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/rescheduling/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.rescheduling.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/rescheduling/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/rescheduling/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.rescheduling.eksekusi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/rescheduling/eksekusi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/rescheduling/eksekusi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.rescheduling.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/rescheduling/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/rescheduling/inquiry"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.rescheduling.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/rescheduling/laporan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/rescheduling/laporan"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.pengalihanKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pengalihanKontrak`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pengalihanKontrak"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pengalihanKontrak.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pengalihanKontrak/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pengalihanKontrak/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pengalihanKontrak.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pengalihanKontrak/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pengalihanKontrak/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pengalihanKontrak.eksekusi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pengalihanKontrak/eksekusi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pengalihanKontrak/eksekusi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.pengalihanKontrak.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/pengalihanKontrak/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/pengalihanKontrak/inquiry"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.penggantianAsset",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/penggantianAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/penggantianAsset"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.penggantianAsset.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/penggantianAsset/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/penggantianAsset/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.penggantianAsset.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/penggantianAsset/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/penggantianAsset/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.penggantianAsset.eksekusi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/penggantianAsset/eksekusi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/penggantianAsset/eksekusi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.penggantianAsset.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/penggantianAsset/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/penggantianAsset/inquiry"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perubahan.gantiCaraBayar",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/gantiCaraBayar`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/gantiCaraBayar"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perubahan.registerVirtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perubahan/registerVirtualAccount`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perubahan/registerVirtualAccount"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.pengembalianKeKonsumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pengembalianKeKonsumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/pengembalianKeKonsumen")
    ),
  },
  {
    key: "coremenu.loanMaintenance.pengembalianKeKonsumen.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pengembalianKeKonsumen/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/pengembalianKeKonsumen/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.pengembalianKeKonsumen.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pengembalianKeKonsumen/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/pengembalianKeKonsumen/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.pengembalianKeKonsumen.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/pengembalianKeKonsumen/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/pengembalianKeKonsumen/inquiry"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff")
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.stopBunga",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.stopBunga.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.stopBunga.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.stopBunga.reversal",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/reversal`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/reversal"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.stopBunga.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/stopBunga/inquiry"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.writeOff",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.writeOff.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.writeOff.approval",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/approval"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.writeOff.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/inquiry"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.writeOff.laporanTransKontrakWriteOff",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/laporanTransKontrakWriteOff`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/writeOff/laporanTransKontrakWriteOff"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.laporanADA",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.laporanADA.kalkulasiADA",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA/kalkulasiADA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA/kalkulasiADA"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.laporanADA.historyADA",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA/historyADA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA/historyADA"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.stopBungadanWriteOff.laporanADA.setupADA",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA/setupADA`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/stopBungadanWriteOff/laporanADA/setupADA"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.fiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/fiducia")
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.registrasiFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/registrasiFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/registrasiFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.registrasiFiduciaHO",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/registrasiFiduciaHO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/registrasiFiduciaHO"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.tandaTerimaExcel",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/tandaTerimaExcel`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/tandaTerimaExcel"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.uploadSertifikatAkta",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/uploadSertifikatAkta`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/uploadSertifikatAkta"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.terimaAktaFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/terimaAktaFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/terimaAktaFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.terimaTagihanFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/terimaTagihanFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/terimaTagihanFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.inquiryFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/inquiryFiducia`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/fiducia/inquiryFiducia")
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.cetakFiduciaRoya",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/cetakFiduciaRoya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/cetakFiduciaRoya"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.daftarNotaris",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/daftarNotaris`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/fiducia/daftarNotaris")
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/fiducia/laporan")
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan.daftarFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan/daftarFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/laporan/daftarFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan.hapusFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan/hapusFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/laporan/hapusFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan.daftarNotaris",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan/daftarNotaris`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/laporan/daftarNotaris"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan.laporanTagihanFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan/laporanTagihanFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/laporan/laporanTagihanFiducia"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan.laporanBelumTerimaAkta",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan/laporanBelumTerimaAkta`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/laporan/laporanBelumTerimaAkta"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.fiducia.laporan.tandaTerimaFiducia",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/laporan/tandaTerimaFiducia`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/fiducia/laporan/tandaTerimaFiducia"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.fiducia.registerRoya",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/fiducia/registerRoya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/fiducia/registerRoya")
    ),
  },

  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/perpanjanganSTNK")
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.pengajuanPerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/pengajuanPerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/pengajuanPerpanjangan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.registrasiKeBiroJasa",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/registrasiKeBiroJasa`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/registrasiKeBiroJasa"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.penambahanEstimasiBiayaSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/penambahanEstimasiBiayaSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/penambahanEstimasiBiayaSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.tagihanDariBiroJasa",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/tagihanDariBiroJasa`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/tagihanDariBiroJasa"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.cetakSuratPerpanjanganSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/cetakSuratPerpanjanganSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/cetakSuratPerpanjanganSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.cetakTagihanSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/cetakTagihanSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/cetakTagihanSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.transaksi.daftarBiroJasa",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/daftarBiroJasa`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/transaksi/daftarBiroJasa"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/inquiry`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/inquiry"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.inquiry.pengajuanPerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/inquiry/pengajuanPerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/inquiry/pengajuanPerpanjangan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.inquiry.registrasiPerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/inquiry/registrasiPerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/inquiry/registrasiPerpanjangan"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan.tagihanSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan/tagihanSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan/tagihanSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan.pendapatanPerpanjanganSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan/pendapatanPerpanjanganSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan/pendapatanPerpanjanganSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan.daftarAgen",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan/daftarAgen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan/daftarAgen"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan.laporanTagihanSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan/laporanTagihanSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan/laporanTagihanSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan.laporanRegisterSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan/laporanRegisterSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan/laporanRegisterSTNK"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.perpanjanganSTNK.laporan.laporanRequestSTNK",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/perpanjanganSTNK/laporan/laporanRequestSTNK`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/perpanjanganSTNK/laporan/laporanRequestSTNK"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.surveyorEksternal",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/surveyorEksternal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/surveyorEksternal")
    ),
  },
  {
    key: "coremenu.loanMaintenance.surveyorEksternal.registrasiAplikasiKeSurveyor",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/surveyorEksternal/registrasiAplikasiKeSurveyor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/surveyorEksternal/registrasiAplikasiKeSurveyor"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.surveyorEksternal.terimaTagihanSurveyor",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/surveyorEksternal/terimaTagihanSurveyor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/surveyorEksternal/terimaTagihanSurveyor"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.surveyorEksternal.laporanTagihanSurveyor",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/surveyorEksternal/laporanTagihanSurveyor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/surveyorEksternal/laporanTagihanSurveyor"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.surveyorEksternal.laporanRegisterSurveyor",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/surveyorEksternal/laporanRegisterSurveyor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/surveyorEksternal/laporanRegisterSurveyor"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/laporan")
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAP",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAP`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/laporan/laporanAP")
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAP.apOtherReport",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAP/apOtherReport`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAP/apOtherReport"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAP.apToInsurance",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAP/apToInsurance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAP/apToInsurance"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.laporan.laporanAR",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/laporan/laporanAR")
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.outstandingAR",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/outstandingAR`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/outstandingAR"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.arInsurance",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/arInsurance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/arInsurance"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.arMutation",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/arMutation`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/arMutation"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.proyeksiAR",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/proyeksiAR`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/proyeksiAR"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.arAnalysisReport",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/arAnalysisReport`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/arAnalysisReport"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.konsumenLunasRakBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/konsumenLunasRakBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/konsumenLunasRakBPKB"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.dfARDetail",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/dfARDetail`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/dfARDetail"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanAR.reportDetailNPV",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanAR/reportDetailNPV`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanAR/reportDetailNPV"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya")
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.accruedInterest",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/accruedInterest`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/accruedInterest"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.pelunasanNormal",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/pelunasanNormal`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/pelunasanNormal"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.pelunasanDipercepat",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/pelunasanDipercepat`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/pelunasanDipercepat"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.konsumenAkanLunas",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/konsumenAkanLunas`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/konsumenAkanLunas"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.pendapatanBunga",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/pendapatanBunga`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/pendapatanBunga"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.titipanKonsumen",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/titipanKonsumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/titipanKonsumen"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.perincianPerhitunganDenda",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/perincianPerhitunganDenda`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/perincianPerhitunganDenda"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.stopAccruedBalance",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/stopAccruedBalance`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/stopAccruedBalance"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.laporanDenda",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/laporanDenda`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/laporanDenda"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.reconVaBCAOnline",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/reconVaBCAOnline`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/reconVaBCAOnline"
      )
    ),
  },
  {
    key: "coremenu.loanMaintenance.laporan.laporanLainnya.laporanGracePeriod",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/laporan/laporanLainnya/laporanGracePeriod`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/loanMaintenance/laporan/laporanLainnya/laporanGracePeriod"
      )
    ),
  },

  {
    key: "coremenu.loanMaintenance.setup",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.daftarBank",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/daftarBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/daftarBank")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.rekeningBank",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/rekeningBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/rekeningBank")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.daftarCabangBank",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/daftarCabangBank`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/daftarCabangBank")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.alokasiAkun",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/alokasiAkun`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/alokasiAkun")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.skemaJurnal",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/skemaJurnal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/skemaJurnal")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.kasir",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/kasir`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/kasir")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.generalSetup",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/generalSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/generalSetup")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.kodeTransaksi",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/kodeTransaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/kodeTransaksi")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.virtualAccount",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/virtualAccount`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/virtualAccount")
    ),
  },
  {
    key: "coremenu.loanMaintenance.setup.setTempAccount",
    path: `${APP_PREFIX_PATH}/coremenu/loanMaintenance/setup/setTempAccount`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/loanMaintenance/setup/setTempAccount")
    ),
  },

  {
    key: "coremenu.collateral",
    path: `${APP_PREFIX_PATH}/coremenu/collateral`,
    component: React.lazy(() => import("views/app-views/coremenu/collateral")),
  },
  {
    key: "coremenu.collateral.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.pengajuanBiayaCekAbsahBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/pengajuanBiayaCekAbsahBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/pengajuanBiayaCekAbsahBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.invoiceCekab",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/invoiceCekab`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/invoiceCekab")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.uploadCekAbsahHO",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/uploadCekAbsahHO`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/uploadCekAbsahHO")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.inquiryPengajuanCekab",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/inquiryPengajuanCekab`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/inquiryPengajuanCekab"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.terimaDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/terimaDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/terimaDokumen")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.koreksiDocument",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/koreksiDocument`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/koreksiDocument")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.dokumenPindahCabang",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/dokumenPindahCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/dokumenPindahCabang"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.pinjamDokumenBiroJasadanEksternalLainnya",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/pinjamDokumenBiroJasadanEksternalLainnya`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/pinjamDokumenBiroJasadanEksternalLainnya"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.dokumenDijaminkanPledgingImpl",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/dokumenDijaminkanPledgingImpl`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/dokumenDijaminkanPledgingImpl"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.terimaDokumenPledgingImpl",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/terimaDokumenPledgingImpl`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/terimaDokumenPledgingImpl"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.dokumenDijaminkan",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/dokumenDijaminkan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/dokumenDijaminkan")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.dokumenKeFunding",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/dokumenKeFunding`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/dokumenKeFunding")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.terimaDokumenDariBank",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/terimaDokumenDariBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/terimaDokumenDariBank"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.penyerahanDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/penyerahanDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/penyerahanDokumen")
    ),
  },
  {
    key: "coremenu.collateral.transaksi.pengambilanBPKBOnline",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/pengambilanBPKBOnline`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/transaksi/pengambilanBPKBOnline"
      )
    ),
  },
  {
    key: "coremenu.collateral.transaksi.cetakLabelBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/transaksi/cetakLabelBPKB`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/transaksi/cetakLabelBPKB")
    ),
  },

  {
    key: "coremenu.collateral.cetakDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/cetakDokumen")
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.cekAbsah",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/cekAbsah`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/cetakDokumen/cekAbsah")
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.perjanjianPinjamNamaBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/perjanjianPinjamNamaBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/perjanjianPinjamNamaBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.permintaanBlokirBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/permintaanBlokirBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/permintaanBlokirBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.permintaanBukaBlokirBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/permintaanBukaBlokirBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/permintaanBukaBlokirBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.tandaTerimaDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/tandaTerimaDokumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/tandaTerimaDokumen"
      )
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.spPenyerahanDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/spPenyerahanDokumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/spPenyerahanDokumen"
      )
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.spPengambilanDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/spPengambilanDokumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/spPengambilanDokumen"
      )
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.cetakLabelBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/cetakLabelBPKB`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/cetakDokumen/cetakLabelBPKB")
    ),
  },
  {
    key: "coremenu.collateral.cetakDokumen.suratKeteranganLunas",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/cetakDokumen/suratKeteranganLunas`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/cetakDokumen/suratKeteranganLunas"
      )
    ),
  },

  {
    key: "coremenu.collateral.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/inquiry")
    ),
  },
  {
    key: "coremenu.collateral.inquiry.statusDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry/statusDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/inquiry/statusDokumen")
    ),
  },
  {
    key: "coremenu.collateral.inquiry.dokumenDipinjam",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry/dokumenDipinjam`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/inquiry/dokumenDipinjam")
    ),
  },
  {
    key: "coremenu.collateral.inquiry.dokumenBelumDiserahkanSupplier",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry/dokumenBelumDiserahkanSupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/inquiry/dokumenBelumDiserahkanSupplier"
      )
    ),
  },
  {
    key: "coremenu.collateral.inquiry.kontrakAkanJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry/kontrakAkanJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/inquiry/kontrakAkanJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.collateral.inquiry.kontrakAssetDitarik",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry/kontrakAssetDitarik`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/inquiry/kontrakAssetDitarik")
    ),
  },
  {
    key: "coremenu.collateral.inquiry.dokumenSudahDiserahkan",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/inquiry/dokumenSudahDiserahkan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/inquiry/dokumenSudahDiserahkan"
      )
    ),
  },

  {
    key: "coremenu.collateral.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan")
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanBPKBMasuk",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanBPKBMasuk`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan/laporanBPKBMasuk")
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanBPKBKeluar",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanBPKBKeluar`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan/laporanBPKBKeluar")
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanBPKBBelumDiterima",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanBPKBBelumDiterima`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/laporanBPKBBelumDiterima"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanSummaryBPKBOVD",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanSummaryBPKBOVD`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/laporanSummaryBPKBOVD"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.daftarPemeriksaanBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/daftarPemeriksaanBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/daftarPemeriksaanBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanPenyerahanBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanPenyerahanBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/laporanPenyerahanBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanPosisiBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanPosisiBPKB`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan/laporanPosisiBPKB")
    ),
  },
  {
    key: "coremenu.collateral.laporan.daftarBPKBBelumDijamin",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/daftarBPKBBelumDijamin`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/daftarBPKBBelumDijamin"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.summaryBPKBBelumDiterima",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/summaryBPKBBelumDiterima`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/summaryBPKBBelumDiterima"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.mutasiDokumenBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/mutasiDokumenBPKB`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan/mutasiDokumenBPKB")
    ),
  },
  {
    key: "coremenu.collateral.laporan.tandaTerimaJaminanBank",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/tandaTerimaJaminanBank`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/tandaTerimaJaminanBank"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.daftarBPKBperTempatSimpan",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/daftarBPKBperTempatSimpan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/daftarBPKBperTempatSimpan"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanMonitoringBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanMonitoringBPKB`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/laporanMonitoringBPKB"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanBPKBBelumBlokir",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanBPKBBelumBlokir`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collateral/laporan/laporanBPKBBelumBlokir"
      )
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanBlokirBPKB",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanBlokirBPKB`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan/laporanBlokirBPKB")
    ),
  },
  {
    key: "coremenu.collateral.laporan.laporanBPKBOD60",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporan/laporanBPKBOD60`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporan/laporanBPKBOD60")
    ),
  },

  {
    key: "coremenu.collateral.setup",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/setup")
    ),
  },
  {
    key: "coremenu.collateral.setup.setupRak",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/setup/setupRak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/setup/setupRak")
    ),
  },

  {
    key: "coremenu.collateral.laporanNew",
    path: `${APP_PREFIX_PATH}/coremenu/collateral/laporanNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collateral/laporanNew")
    ),
  },

  {
    key: "coremenu.insurance",
    path: `${APP_PREFIX_PATH}/coremenu/insurance`,
    component: React.lazy(() => import("views/app-views/coremenu/insurance")),
  },
  {
    key: "coremenu.insurance.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.inputOrderAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/inputOrderAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/inputOrderAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.koreksiMaskapaiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/koreksiMaskapaiAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/koreksiMaskapaiAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.sppaMNCOnline",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/sppaMNCOnline`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/sppaMNCOnline")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.sppaMNCOnline.submitDataAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/sppaMNCOnline/submitDataAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/sppaMNCOnline/submitDataAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.sppaMNCOnline.inquiryDataAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/sppaMNCOnline/inquiryDataAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/sppaMNCOnline/inquiryDataAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.sppaMNCOnline.laporanSPPAOnline",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/sppaMNCOnline/laporanSPPAOnline`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/sppaMNCOnline/laporanSPPAOnline"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.sppaMNCOnline.notaAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/sppaMNCOnline/notaAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/sppaMNCOnline/notaAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.sppaMNCOnline.resubmitInsuranceFL",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/sppaMNCOnline/resubmitInsuranceFL`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/sppaMNCOnline/resubmitInsuranceFL"
      )
    ),
  },

  {
    key: "coremenu.insurance.transaksi.terimaPolis",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/terimaPolis`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/terimaPolis")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.terimaPolisPerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/terimaPolisPerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/terimaPolisPerpanjangan"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.koreksiNoPolis",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/koreksiNoPolis`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/koreksiNoPolis")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.terimaPolisBatch",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/terimaPolisBatch`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/terimaPolisBatch")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.endorsment",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/endorsment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/endorsment")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.endorsmentBankersClause",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/endorsmentBankersClause`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/endorsmentBankersClause"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.extendInsuranceCover",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/extendInsuranceCover`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/extendInsuranceCover"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.extendInsuranceApproval",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/extendInsuranceApproval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/extendInsuranceApproval"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.tagihanInsurance",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/tagihanInsurance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/tagihanInsurance")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.tagihanInsurancePerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/tagihanInsurancePerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/tagihanInsurancePerpanjangan"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.refundPremiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/refundPremiAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/transaksi/refundPremiAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.transaksi.koreksiCoveragePerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/koreksiCoveragePerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/koreksiCoveragePerpanjangan"
      )
    ),
  },
  {
    key: "coremenu.insurance.transaksi.coverageAplikasiSudahAda",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/transaksi/coverageAplikasiSudahAda`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/transaksi/coverageAplikasiSudahAda"
      )
    ),
  },

  {
    key: "coremenu.insurance.cetakDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/cetakDokumen")
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.cetakSPPA",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/cetakSPPA`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/cetakDokumen/cetakSPPA")
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.bankersClause",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/bankersClause`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/cetakDokumen/bankersClause")
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.cetakUlangSPPA",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/cetakUlangSPPA`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/cetakDokumen/cetakUlangSPPA")
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.tagihanAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/tagihanAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/cetakDokumen/tagihanAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.suratPerpanjangan",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/suratPerpanjangan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/cetakDokumen/suratPerpanjangan"
      )
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.cetakSPPAExtend",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/cetakSPPAExtend`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/cetakDokumen/cetakSPPAExtend")
    ),
  },
  {
    key: "coremenu.insurance.cetakDokumen.cetakUlangSPPAExtend",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/cetakDokumen/cetakUlangSPPAExtend`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/cetakDokumen/cetakUlangSPPAExtend"
      )
    ),
  },

  {
    key: "coremenu.insurance.klaim",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/klaim`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/klaim")
    ),
  },
  {
    key: "coremenu.insurance.klaim.pengajuanKlaim",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/klaim/pengajuanKlaim`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/klaim/pengajuanKlaim")
    ),
  },
  {
    key: "coremenu.insurance.klaim.aktivitasKlaim",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/klaim/aktivitasKlaim`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/klaim/aktivitasKlaim")
    ),
  },

  {
    key: "coremenu.insurance.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry")
    ),
  },
  {
    key: "coremenu.insurance.inquiry.coverAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry/coverAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry/coverAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.inquiry.tagihan",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry/tagihan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry/tagihan")
    ),
  },
  {
    key: "coremenu.insurance.inquiry.klaim",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry/klaim`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry/klaim")
    ),
  },
  {
    key: "coremenu.insurance.inquiry.refundPremi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry/refundPremi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry/refundPremi")
    ),
  },
  {
    key: "coremenu.insurance.inquiry.endorsment",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry/endorsment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry/endorsment")
    ),
  },
  {
    key: "coremenu.insurance.inquiry.jatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/inquiry/jatuhTempo`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/inquiry/jatuhTempo")
    ),
  },

  {
    key: "coremenu.insurance.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan")
    ),
  },
  {
    key: "coremenu.insurance.laporan.daftarPemeriksaanAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/daftarPemeriksaanAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/daftarPemeriksaanAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.daftarPenerimaanPolis",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/daftarPenerimaanPolis`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/daftarPenerimaanPolis")
    ),
  },
  {
    key: "coremenu.insurance.laporan.daftarPertanggunganAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/daftarPertanggunganAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/daftarPertanggunganAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanEndorsment",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanEndorsment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/laporanEndorsment")
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanStatistikAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanStatistikAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/laporanStatistikAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanTagihanAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanTagihanAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/laporanTagihanAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanAPKBelumCover",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanAPKBelumCover`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/laporanAPKBelumCover")
    ),
  },
  {
    key: "coremenu.insurance.laporan.daftarMaskapaiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/daftarMaskapaiAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/daftarMaskapaiAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.leadTimeInsurance",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/leadTimeInsurance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/leadTimeInsurance")
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanAsuransiJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanAsuransiJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/laporanAsuransiJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanPerpanjanganAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanPerpanjanganAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/laporanPerpanjanganAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.lapOutstandingRefundPremi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/lapOutstandingRefundPremi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/lapOutstandingRefundPremi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanHutangAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanHutangAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/laporanHutangAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanOutstandingKlaim",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanOutstandingKlaim`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/laporanOutstandingKlaim"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanExtendAsuransiRelaksasi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanExtendAsuransiRelaksasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/laporan/laporanExtendAsuransiRelaksasi"
      )
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanQuotaAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanQuotaAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/laporanQuotaAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.laporan.laporanKlaimAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/laporan/laporanKlaimAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/laporan/laporanKlaimAsuransi")
    ),
  },

  {
    key: "coremenu.insurance.setup",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup")
    ),
  },
  {
    key: "coremenu.insurance.setup.kuotaAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/kuotaAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/kuotaAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.setup.dokumenAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/dokumenAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/dokumenAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.setup.dokumenKlaim",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/dokumenKlaim`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/dokumenKlaim")
    ),
  },
  {
    key: "coremenu.insurance.setup.generalSetup",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/generalSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/generalSetup")
    ),
  },
  {
    key: "coremenu.insurance.setup.masterSellingRate",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/masterSellingRate`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/masterSellingRate")
    ),
  },
  {
    key: "coremenu.insurance.setup.newSellingRateCard",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/newSellingRateCard`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/newSellingRateCard")
    ),
  },
  {
    key: "coremenu.insurance.setup.masterHPPRate",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/masterHPPRate`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/masterHPPRate")
    ),
  },
  {
    key: "coremenu.insurance.setup.maskapaiAsuransidanCabang",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/maskapaiAsuransidanCabang`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/setup/maskapaiAsuransidanCabang"
      )
    ),
  },
  {
    key: "coremenu.insurance.setup.newStandardRate",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/setup/newStandardRate`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/setup/newStandardRate")
    ),
  },

  {
    key: "coremenu.insurance.sentralisasiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/sentralisasiAsuransi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/insurance/sentralisasiAsuransi")
    ),
  },
  {
    key: "coremenu.insurance.sentralisasiAsuransi.pengajuanKlaimSentralisasiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/sentralisasiAsuransi/pengajuanKlaimSentralisasiAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/sentralisasiAsuransi/pengajuanKlaimSentralisasiAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.sentralisasiAsuransi.penerimaanKlaimSentralisasiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/sentralisasiAsuransi/penerimaanKlaimSentralisasiAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/sentralisasiAsuransi/penerimaanKlaimSentralisasiAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.sentralisasiAsuransi.uploadTerimaKlaimAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/sentralisasiAsuransi/uploadTerimaKlaimAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/sentralisasiAsuransi/uploadTerimaKlaimAsuransi"
      )
    ),
  },
  {
    key: "coremenu.insurance.sentralisasiAsuransi.listPenerimaanKlaimSentralisasiAsuransi",
    path: `${APP_PREFIX_PATH}/coremenu/insurance/sentralisasiAsuransi/listPenerimaanKlaimSentralisasiAsuransi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/insurance/sentralisasiAsuransi/listPenerimaanKlaimSentralisasiAsuransi"
      )
    ),
  },

  {
    key: "coremenu.amu",
    path: `${APP_PREFIX_PATH}/coremenu/amu`,
    component: React.lazy(() => import("views/app-views/coremenu/amu")),
  },
  {
    key: "coremenu.amu.penarikanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/amu/penarikanAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/penarikanAsset")
    ),
  },
  {
    key: "coremenu.amu.approvalPenarikanAssetCabang",
    path: `${APP_PREFIX_PATH}/coremenu/amu/approvalPenarikanAssetCabang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/approvalPenarikanAssetCabang")
    ),
  },
  {
    key: "coremenu.amu.approvalPenarikanAssetHO",
    path: `${APP_PREFIX_PATH}/coremenu/amu/approvalPenarikanAssetHO`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/approvalPenarikanAssetHO")
    ),
  },
  {
    key: "coremenu.amu.cetakSPLelang2",
    path: `${APP_PREFIX_PATH}/coremenu/amu/cetakSPLelang2`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/cetakSPLelang2")
    ),
  },
  {
    key: "coremenu.amu.pemeriksaanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/amu/pemeriksaanAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/pemeriksaanAsset")
    ),
  },
  {
    key: "coremenu.amu.taksasi",
    path: `${APP_PREFIX_PATH}/coremenu/amu/taksasi`,
    component: React.lazy(() => import("views/app-views/coremenu/amu/taksasi")),
  },
  {
    key: "coremenu.amu.taksasiAssetHo",
    path: `${APP_PREFIX_PATH}/coremenu/amu/taksasiAssetHo`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/taksasiAssetHo")
    ),
  },
  {
    key: "coremenu.amu.amuInventoryOnRequest",
    path: `${APP_PREFIX_PATH}/coremenu/amu/amuInventoryOnRequest`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/amuInventoryOnRequest")
    ),
  },
  {
    key: "coremenu.amu.pengajuanBiaya",
    path: `${APP_PREFIX_PATH}/coremenu/amu/pengajuanBiaya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/pengajuanBiaya")
    ),
  },
  {
    key: "coremenu.amu.approvalPengajuanBiaya",
    path: `${APP_PREFIX_PATH}/coremenu/amu/approvalPengajuanBiaya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/approvalPengajuanBiaya")
    ),
  },
  {
    key: "coremenu.amu.penentuanCaraJual",
    path: `${APP_PREFIX_PATH}/coremenu/amu/penentuanCaraJual`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/penentuanCaraJual")
    ),
  },
  {
    key: "coremenu.amu.rekomendasiBasePrice",
    path: `${APP_PREFIX_PATH}/coremenu/amu/rekomendasiBasePrice`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/rekomendasiBasePrice")
    ),
  },
  {
    key: "coremenu.amu.hasilLelang",
    path: `${APP_PREFIX_PATH}/coremenu/amu/hasilLelang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/hasilLelang")
    ),
  },
  {
    key: "coremenu.amu.terimaPembayaranHasilLelang",
    path: `${APP_PREFIX_PATH}/coremenu/amu/terimaPembayaranHasilLelang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/terimaPembayaranHasilLelang")
    ),
  },
  {
    key: "coremenu.amu.terimaPembayaranHasilLelangWanprestasi",
    path: `${APP_PREFIX_PATH}/coremenu/amu/terimaPembayaranHasilLelangWanprestasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/amu/terimaPembayaranHasilLelangWanprestasi"
      )
    ),
  },
  {
    key: "coremenu.amu.otorisasiTerimaPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/amu/otorisasiTerimaPembayaran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/otorisasiTerimaPembayaran")
    ),
  },
  {
    key: "coremenu.amu.otorisasiTerimaPembayaranWanprestasi",
    path: `${APP_PREFIX_PATH}/coremenu/amu/otorisasiTerimaPembayaranWanprestasi`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/amu/otorisasiTerimaPembayaranWanprestasi"
      )
    ),
  },
  {
    key: "coremenu.amu.koreksiTerimaPembayaran",
    path: `${APP_PREFIX_PATH}/coremenu/amu/koreksiTerimaPembayaran`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/koreksiTerimaPembayaran")
    ),
  },
  {
    key: "coremenu.amu.laporanHasilLelang",
    path: `${APP_PREFIX_PATH}/coremenu/amu/laporanHasilLelang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/laporanHasilLelang")
    ),
  },
  {
    key: "coremenu.amu.laporanAssetTerjualNonLelang",
    path: `${APP_PREFIX_PATH}/coremenu/amu/laporanAssetTerjualNonLelang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/laporanAssetTerjualNonLelang")
    ),
  },
  {
    key: "coremenu.amu.eksekusiPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/amu/eksekusiPenjualanAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/eksekusiPenjualanAsset")
    ),
  },
  {
    key: "coremenu.amu.setup",
    path: `${APP_PREFIX_PATH}/coremenu/amu/setup`,
    component: React.lazy(() => import("views/app-views/coremenu/amu/setup")),
  },
  {
    key: "coremenu.amu.setup.balaiLelang",
    path: `${APP_PREFIX_PATH}/coremenu/amu/setup/balaiLelang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/setup/balaiLelang")
    ),
  },
  {
    key: "coremenu.amu.wanprestasi",
    path: `${APP_PREFIX_PATH}/coremenu/amu/wanprestasi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/wanprestasi")
    ),
  },
  {
    key: "coremenu.amu.wanprestasi.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/amu/wanprestasi/pengajuan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/wanprestasi/pengajuan")
    ),
  },
  {
    key: "coremenu.amu.wanprestasi.otorisasi",
    path: `${APP_PREFIX_PATH}/coremenu/amu/wanprestasi/otorisasi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/amu/wanprestasi/otorisasi")
    ),
  },

  {
    key: "coremenu.collection",
    path: `${APP_PREFIX_PATH}/coremenu/collection`,
    component: React.lazy(() => import("views/app-views/coremenu/collection")),
  },
  {
    key: "coremenu.collection.deskColl",
    path: `${APP_PREFIX_PATH}/coremenu/collection/deskColl`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/deskColl")
    ),
  },
  {
    key: "coremenu.collection.deskColl.deskCollRemindActivity",
    path: `${APP_PREFIX_PATH}/coremenu/collection/deskColl/deskCollRemindActivity`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/deskColl/deskCollRemindActivity"
      )
    ),
  },
  {
    key: "coremenu.collection.deskColl.deskCollOverdueActivity",
    path: `${APP_PREFIX_PATH}/coremenu/collection/deskColl/deskCollOverdueActivity`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/deskColl/deskCollOverdueActivity"
      )
    ),
  },
  {
    key: "coremenu.collection.deskColl.accountForecasting",
    path: `${APP_PREFIX_PATH}/coremenu/collection/deskColl/accountForecasting`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/deskColl/accountForecasting")
    ),
  },
  {
    key: "coremenu.collection.fieldCollection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/fieldCollection")
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.rencanaDCR",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/rencanaDCR`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/fieldCollection/rencanaDCR")
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.cetakDCR",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/cetakDCR`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/fieldCollection/cetakDCR")
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.cetakSP",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/cetakSP`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/fieldCollection/cetakSP")
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.hasilKunjungan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/hasilKunjungan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/fieldCollection/hasilKunjungan"
      )
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.supervisorActivity",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/supervisorActivity`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/fieldCollection/supervisorActivity"
      )
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.distribusiDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/distribusiDokumen`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/fieldCollection/distribusiDokumen"
      )
    ),
  },
  {
    key: "coremenu.collection.fieldCollection.collectionTaskOnRequest",
    path: `${APP_PREFIX_PATH}/coremenu/collection/fieldCollection/collectionTaskOnRequest`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/fieldCollection/collectionTaskOnRequest"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/collectionMacet")
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.suratKuasaTarikSKT",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/suratKuasaTarikSKT`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/suratKuasaTarikSKT"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.suratKuasaTarikSKT.cetakSKT",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/suratKuasaTarikSKT/cetakSKT`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/suratKuasaTarikSKT/cetakSKT"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.suratKuasaTarikSKT.cetakSKTonRequest",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/suratKuasaTarikSKT/cetakSKTonRequest`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/suratKuasaTarikSKT/cetakSKTonRequest"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.suratKuasaTarikSKT.perpanjanganSKT",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/suratKuasaTarikSKT/perpanjanganSKT`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/suratKuasaTarikSKT/perpanjanganSKT"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.suratKuasaTarikSKT.gantiEksekutor",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/suratKuasaTarikSKT/gantiEksekutor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/suratKuasaTarikSKT/gantiEksekutor"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.suratKuasaTarikSKT.penarikanSKT",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/suratKuasaTarikSKT/penarikanSKT`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/suratKuasaTarikSKT/penarikanSKT"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.penarikanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/penarikanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/penarikanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.pembayaranLainTerkaitRepoAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/pembayaranLainTerkaitRepoAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/pembayaranLainTerkaitRepoAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.pembayaranLainTerkaitRepoAssetOtor",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/pembayaranLainTerkaitRepoAssetOtor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/pembayaranLainTerkaitRepoAssetOtor"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.approval",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/approval"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.approvalRemedial",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/approvalRemedial`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/approvalRemedial"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.approvalRecovery",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/approvalRecovery`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/approvalRecovery"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.biayaCollection.biayaPenanganan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/biayaCollection/biayaPenanganan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/biayaCollection/biayaPenanganan"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.cetakLPK",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/cetakLPK`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/collectionMacet/cetakLPK")
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.melanjutkanKredit",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/melanjutkanKredit`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/melanjutkanKredit"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.melanjutkanKredit.pengajuan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/melanjutkanKredit/pengajuan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/melanjutkanKredit/pengajuan"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.melanjutkanKredit.approval",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/melanjutkanKredit/approval`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/melanjutkanKredit/approval"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/collectionMacet/arTarikan")
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.perpanjanganMasaTunggu",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/perpanjanganMasaTunggu`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/perpanjanganMasaTunggu"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.penilaianAssetTarikan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/penilaianAssetTarikan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/penilaianAssetTarikan"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.approvalPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/approvalPenjualanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/approvalPenjualanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.penjualanAssetTarikan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/penjualanAssetTarikan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/penjualanAssetTarikan"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.percepatanJualAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/percepatanJualAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/percepatanJualAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.approvalPercepatanJualAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/approvalPercepatanJualAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/approvalPercepatanJualAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.arTarikan.pembatalanAssetTarikan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/arTarikan/pembatalanAssetTarikan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/arTarikan/pembatalanAssetTarikan"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.pelepasanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/pelepasanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/pelepasanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.cetakBPK",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/cetakBPK`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/collectionMacet/cetakBPK")
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.bukaBlokirBayar",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/bukaBlokirBayar`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/bukaBlokirBayar"
      )
    ),
  },
  {
    key: "coremenu.collection.collectionMacet.bukaBlokirBayarTunai",
    path: `${APP_PREFIX_PATH}/coremenu/collection/collectionMacet/bukaBlokirBayarTunai`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/collectionMacet/bukaBlokirBayarTunai"
      )
    ),
  },

  {
    key: "coremenu.collection.aktivitasAdminColl",
    path: `${APP_PREFIX_PATH}/coremenu/collection/aktivitasAdminColl`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/aktivitasAdminColl")
    ),
  },
  {
    key: "coremenu.collection.gantiAlamat",
    path: `${APP_PREFIX_PATH}/coremenu/collection/gantiAlamat`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/gantiAlamat")
    ),
  },
  {
    key: "coremenu.collection.gantiAlamat.gantiAlamat",
    path: `${APP_PREFIX_PATH}/coremenu/collection/gantiAlamat/gantiAlamat`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/gantiAlamat/gantiAlamat")
    ),
  },
  {
    key: "coremenu.collection.gantiAlamat.inquiryGantiAlamat",
    path: `${APP_PREFIX_PATH}/coremenu/collection/gantiAlamat/inquiryGantiAlamat`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/gantiAlamat/inquiryGantiAlamat"
      )
    ),
  },
  {
    key: "coremenu.collection.gantiAlamat.persetujuanGantiAlamat",
    path: `${APP_PREFIX_PATH}/coremenu/collection/gantiAlamat/persetujuanGantiAlamat`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/gantiAlamat/persetujuanGantiAlamat"
      )
    ),
  },
  {
    key: "coremenu.collection.gantiAlamat.pembaharuanDataCustomerOnline",
    path: `${APP_PREFIX_PATH}/coremenu/collection/gantiAlamat/pembaharuanDataCustomerOnline`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/gantiAlamat/pembaharuanDataCustomerOnline"
      )
    ),
  },

  {
    key: "coremenu.collection.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/collection/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/inquiry")
    ),
  },
  {
    key: "coremenu.collection.inquiry.inquiryStepCollection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/inquiry/inquiryStepCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/inquiry/inquiryStepCollection"
      )
    ),
  },
  {
    key: "coremenu.collection.inquiry.dcr",
    path: `${APP_PREFIX_PATH}/coremenu/collection/inquiry/dcr`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/inquiry/dcr")
    ),
  },
  {
    key: "coremenu.collection.inquiry.hasilKunjungan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/inquiry/hasilKunjungan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/inquiry/hasilKunjungan")
    ),
  },
  {
    key: "coremenu.collection.inquiry.assetTarikan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/inquiry/assetTarikan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/inquiry/assetTarikan")
    ),
  },
  {
    key: "coremenu.collection.inquiry.distribusiDokumen",
    path: `${APP_PREFIX_PATH}/coremenu/collection/inquiry/distribusiDokumen`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/inquiry/distribusiDokumen")
    ),
  },
  {
    key: "coremenu.collection.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan")
    ),
  },
  {
    key: "coremenu.collection.laporan.collection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan/collection")
    ),
  },
  {
    key: "coremenu.collection.laporan.collection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan/collection")
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.collectibility",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/collectibility`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/collectibility"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.laporanKegiatanDeskColl",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/laporanKegiatanDeskColl`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/laporanKegiatanDeskColl"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.callCenterActivityReport",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/callCenterActivityReport`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/callCenterActivityReport"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.callCenterAgentActivityRpt",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/callCenterAgentActivityRpt`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/callCenterAgentActivityRpt"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.laporanHasilKunjunganCollector",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/laporanHasilKunjunganCollector`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/laporanHasilKunjunganCollector"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.fieldCollSummaryActivity",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/fieldCollSummaryActivity`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/fieldCollSummaryActivity"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.fieldCollActivityReport",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/fieldCollActivityReport`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/fieldCollActivityReport"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.laporanRekapSP",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/laporanRekapSP`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/laporanRekapSP"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.collection.laporanBiayaCollection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/collection/laporanBiayaCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/collection/laporanBiayaCollection"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan/macet")
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.laporanStokAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/laporanStokAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/laporanStokAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.laporanUsiaStockAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/laporanUsiaStockAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/laporanUsiaStockAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.laporanPenarikanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/laporanPenarikanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/laporanPenarikanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.laporanHasilPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/laporanHasilPenjualanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/laporanHasilPenjualanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.laporanRecoveryAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/laporanRecoveryAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/laporanRecoveryAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.daftarSuratKuasaTarik",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/daftarSuratKuasaTarik`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/daftarSuratKuasaTarik"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.suratKuasaTarikTanpaExecutor",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/suratKuasaTarikTanpaExecutor`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/suratKuasaTarikTanpaExecutor"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.laporanAssetSiapJual",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/laporanAssetSiapJual`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/laporanAssetSiapJual"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.macet.approvalTaksasiAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/macet/approvalTaksasiAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/macet/approvalTaksasiAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan/laporanAR")
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanARMoveByOSArea",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanARMoveByOSArea`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanARMoveByOSArea"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanARMoveByOS",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanARMoveByOS`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanARMoveByOS"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanARMoveByUnitArea",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanARMoveByUnitArea`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanARMoveByUnitArea"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanARMoveByUNIT",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanARMoveByUNIT`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanARMoveByUNIT"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanARSummary",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanARSummary`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanARSummary"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanOVDByCMO",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanOVDByCMO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanOVDByCMO"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanOVDBySupplier",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanOVDBySupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanOVDBySupplier"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanARDetail",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanARDetail`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanARDetail"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanDPDCollection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanDPDCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanDPDCollection"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanDPDDetail",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanDPDDetail`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanDPDDetail"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.aROverdueByCMO",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/aROverdueByCMO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/aROverdueByCMO"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.aROverdueByCollector",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/aROverdueByCollector`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/aROverdueByCollector"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.aROverdueBySupplier",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/aROverdueBySupplier`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/aROverdueBySupplier"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.aROverdueStatistikbyCMO",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/aROverdueStatistikbyCMO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/aROverdueStatistikbyCMO"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanKreditDeliquencyLite",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanKreditDeliquencyLite`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanKreditDeliquencyLite"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanCreditDeliquency",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanCreditDeliquency`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanCreditDeliquency"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.tagihanJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/tagihanJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/tagihanJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.tunggakanAngsuranKe2Sd5",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/tunggakanAngsuranKe2Sd5`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/tunggakanAngsuranKe2Sd5"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanF19PD",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanF19PD`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanF19PD"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.tunggakanByCMO",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/tunggakanByCMO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/tunggakanByCMO"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanAkhirBulanCollection",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanAkhirBulanCollection`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanAkhirBulanCollection"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.flowRate",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/flowRate`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan/laporanAR/flowRate")
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanAR.laporanRekapWO",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanAR/laporanRekapWO`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanAR/laporanRekapWO"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/laporan/laporanCG")
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.laporanCreditDeliquencyLite",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/laporanCreditDeliquencyLite`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/laporanCreditDeliquencyLite"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.laporanStokAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/laporanStokAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/laporanStokAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.laporanPenarikanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/laporanPenarikanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/laporanPenarikanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.laporanHasilPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/laporanHasilPenjualanAsset`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/laporanHasilPenjualanAsset"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.laporanPelunasan",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/laporanPelunasan`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/laporanPelunasan"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.laporanPelunasanNormal",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/laporanPelunasanNormal`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/laporanPelunasanNormal"
      )
    ),
  },
  {
    key: "coremenu.collection.laporan.laporanCG.daftarSuratKuasaTarik",
    path: `${APP_PREFIX_PATH}/coremenu/collection/laporan/laporanCG/daftarSuratKuasaTarik`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/laporan/laporanCG/daftarSuratKuasaTarik"
      )
    ),
  },

  {
    key: "coremenu.collection.setup",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup")
    ),
  },
  {
    key: "coremenu.collection.setup.collResult",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/collResult`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/collResult")
    ),
  },
  {
    key: "coremenu.collection.setup.alokasiDeskColl",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/alokasiDeskColl`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/alokasiDeskColl")
    ),
  },
  {
    key: "coremenu.collection.setup.collectionGroup",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/collectionGroup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/collectionGroup")
    ),
  },
  {
    key: "coremenu.collection.setup.daftarCollector",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/daftarCollector`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/daftarCollector")
    ),
  },
  {
    key: "coremenu.collection.setup.daftarEksekutor",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/daftarEksekutor`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/daftarEksekutor")
    ),
  },
  {
    key: "coremenu.collection.setup.mappingCollector",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/mappingCollector`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/mappingCollector")
    ),
  },
  {
    key: "coremenu.collection.setup.mappingRepo",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/mappingRepo`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/mappingRepo")
    ),
  },
  {
    key: "coremenu.collection.setup.mappingPAO",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/mappingPAO`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/mappingPAO")
    ),
  },
  {
    key: "coremenu.collection.setup.rollingCollector",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/rollingCollector`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/rollingCollector")
    ),
  },
  {
    key: "coremenu.collection.setup.rollingWilayahCollector",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/rollingWilayahCollector`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/collection/setup/rollingWilayahCollector"
      )
    ),
  },
  {
    key: "coremenu.collection.setup.kasus",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/kasus`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/kasus")
    ),
  },
  {
    key: "coremenu.collection.setup.checkListAsset",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/checkListAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/checkListAsset")
    ),
  },
  {
    key: "coremenu.collection.setup.generalSetting",
    path: `${APP_PREFIX_PATH}/coremenu/collection/setup/generalSetting`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/collection/setup/generalSetting")
    ),
  },

  {
    key: "coremenu.inquiries",
    path: `${APP_PREFIX_PATH}/coremenu/inquiries`,
    component: React.lazy(() => import("views/app-views/coremenu/inquiries")),
  },
  {
    key: "coremenu.inquiries.daftarKontak",
    path: `${APP_PREFIX_PATH}/coremenu/inquiries/daftarKontak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/inquiries/daftarKontak")
    ),
  },

  {
    key: "coremenu.setup",
    path: `${APP_PREFIX_PATH}/coremenu/setup`,
    component: React.lazy(() => import("views/app-views/coremenu/setup")),
  },
  {
    key: "coremenu.setup.organization",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization")
    ),
  },
  {
    key: "coremenu.setup.organization.perusahaan",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/perusahaan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/perusahaan")
    ),
  },
  {
    key: "coremenu.setup.organization.perusahaan.AddEditPerusahaan",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/perusahaan/AddEditPerusahaan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/perusahaan/AddEditPerusahaan")
    ),
  },
  {
    key: "coremenu.setup.organization.regional",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/regional`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/regional")
    ),
  },
  {
    key: "coremenu.setup.organization.area",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/area`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/area")
    ),
  },
  {
    key: "coremenu.setup.organization.area.AddEditArea",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/area/AddEditArea`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/area/AddEditArea")
    ),
  },
  {
    key: "coremenu.setup.organization.cabang",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/cabang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/branch")
    ),
  },
  {
    key: "coremenu.setup.organization.employee",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/employee`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/organization/employee")
    ),
  },
  {
    key: "coremenu.setup.organization.employee",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/employee/form/*`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/setup/organization/employee/EmployeeForm"
      )
    ),
  },
  {
    key: "coremenu.setup.organization.employee",
    path: `${APP_PREFIX_PATH}/coremenu/setup/organization/employee/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/setup/organization/employee/EmployeeForm"
      )
    ),
  },

  {
    key: "coremenu.setup.generalSetup",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.syaratdanKondisi",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/syaratdanKondisi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/syaratdanKondisi")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.sektorEkonomi",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/sektorEkonomi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/sektorEkonomi")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.jenisIndustri",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/jenisIndustri`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/jenisIndustri")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.kodePos",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/kodePos`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/kodePos")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.kabupatenKotamadya",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/kabupatenKotamadya`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/kabupatenKotamadya")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.settingProvinsi",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/settingProvinsi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/settingProvinsi")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.referensi",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/referensi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/referensi")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.jabatanKaryawan",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/jabatanKaryawan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/jabatanKaryawan")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.alasan",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/alasan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/alasan")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.jadwalHariLibur",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/jadwalHariLibur`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/jadwalHariLibur")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.generalSetup",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/generalSetup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/generalSetup")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.authorizedSigner",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/authorizedSigner`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/authorizedSigner")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.daftarVendor",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/daftarVendor`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/daftarVendor")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.settingLaporan",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/settingLaporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/settingLaporan")
    ),
  },
  {
    key: "coremenu.setup.generalSetup.setupMarketingTour",
    path: `${APP_PREFIX_PATH}/coremenu/setup/generalSetup/setupMarketingTour`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/generalSetup/setupMarketingTour")
    ),
  },

  {
    key: "coremenu.setup.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/setup/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/laporan")
    ),
  },
  {
    key: "coremenu.setup.laporan.daftarVendor",
    path: `${APP_PREFIX_PATH}/coremenu/setup/laporan/daftarVendor`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/setup/laporan/daftarVendor")
    ),
  },

  {
    key: "coremenu.approval",
    path: `${APP_PREFIX_PATH}/coremenu/approval`,
    component: React.lazy(() => import("views/app-views/coremenu/approval")),
  },
  {
    key: "coremenu.approval.limitApprovalCabang",
    path: `${APP_PREFIX_PATH}/coremenu/approval/limitApprovalCabang`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/approval/limitApprovalCabang")
    ),
  },
  {
    key: "coremenu.approval.skemaMasterApproval",
    path: `${APP_PREFIX_PATH}/coremenu/approval/skemaMasterApproval`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/approval/skemaMasterApproval")
    ),
  },
  {
    key: "coremenu.approval.skemaApproval",
    path: `${APP_PREFIX_PATH}/coremenu/approval/skemaApproval`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/approval/skemaApproval")
    ),
  },
  {
    key: "coremenu.approval.approvalComponent",
    path: `${APP_PREFIX_PATH}/coremenu/approval/approvalComponent`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/approval/approvalComponent")
    ),
  },

  {
    key: "coremenu.gl",
    path: `${APP_PREFIX_PATH}/coremenu/gl`,
    component: React.lazy(() => import("views/app-views/coremenu/gl")),
  },
  {
    key: "coremenu.gl.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi")
    ),
  },
  {
    key: "coremenu.gl.transaksi.entryJurnal",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/entryJurnal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/entryJurnal")
    ),
  },
  {
    key: "coremenu.gl.transaksi.editJurnal",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/editJurnal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/editJurnal")
    ),
  },
  {
    key: "coremenu.gl.transaksi.importJurnal",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/importJurnal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/importJurnal")
    ),
  },
  {
    key: "coremenu.gl.transaksi.validasiJurnal",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/validasiJurnal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/validasiJurnal")
    ),
  },
  {
    key: "coremenu.gl.transaksi.approvalJurnal",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/approvalJurnal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/approvalJurnal")
    ),
  },
  {
    key: "coremenu.gl.transaksi.postingHarian",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/postingHarian`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/postingHarian")
    ),
  },
  {
    key: "coremenu.gl.transaksi.removeJournal",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/removeJournal`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/removeJournal")
    ),
  },
  {
    key: "coremenu.gl.transaksi.updateGLBalance",
    path: `${APP_PREFIX_PATH}/coremenu/gl/transaksi/updateGLBalance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/transaksi/updateGLBalance")
    ),
  },
  {
    key: "coremenu.gl.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/gl/inquiry`,
    component: React.lazy(() => import("views/app-views/coremenu/gl/inquiry")),
  },
  {
    key: "coremenu.gl.inquiry.jurnalGL",
    path: `${APP_PREFIX_PATH}/coremenu/gl/inquiry/jurnalGL`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/inquiry/jurnalGL")
    ),
  },
  {
    key: "coremenu.gl.inquiry.journalInvalid",
    path: `${APP_PREFIX_PATH}/coremenu/gl/inquiry/journalInvalid`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/inquiry/journalInvalid")
    ),
  },
  {
    key: "coremenu.gl.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan`,
    component: React.lazy(() => import("views/app-views/coremenu/gl/laporan")),
  },
  {
    key: "coremenu.gl.laporan.daftarJurnalSummary",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/daftarJurnalSummary`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/daftarJurnalSummary")
    ),
  },
  {
    key: "coremenu.gl.laporan.daftarJurnalDetail",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/daftarJurnalSummary`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/daftarJurnalSummary")
    ),
  },
  {
    key: "coremenu.gl.laporan.daftarJurnalDetailPerAkun",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/daftarJurnalDetailPerAkun`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/daftarJurnalDetailPerAkun")
    ),
  },
  {
    key: "coremenu.gl.laporan.daftarJurnalInvalid",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/daftarJurnalInvalid`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/daftarJurnalInvalid")
    ),
  },
  {
    key: "coremenu.gl.laporan.daftarJurnalPending",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/daftarJurnalPending`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/daftarJurnalPending")
    ),
  },
  {
    key: "coremenu.gl.laporan.generalLedger",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/generalLedger`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/generalLedger")
    ),
  },
  {
    key: "coremenu.gl.laporan.daftarTrialBalance",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/daftarTrialBalance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/daftarTrialBalance")
    ),
  },
  {
    key: "coremenu.gl.laporan.balanceSheet",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/balanceSheet`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/balanceSheet")
    ),
  },
  {
    key: "coremenu.gl.laporan.balanceSheetDetail",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/balanceSheetDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/balanceSheetDetail")
    ),
  },
  {
    key: "coremenu.gl.laporan.incomeStatement",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/incomeStatement`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/incomeStatement")
    ),
  },
  {
    key: "coremenu.gl.laporan.incomeStatementDetail",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/incomeStatementDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/incomeStatementDetail")
    ),
  },
  {
    key: "coremenu.gl.laporan.operatingExpenseDetail",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/operatingExpenseDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/operatingExpenseDetail")
    ),
  },
  {
    key: "coremenu.gl.laporan.jurnalDetailExpense",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/jurnalDetailExpense`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/jurnalDetailExpense")
    ),
  },
  {
    key: "coremenu.gl.laporan.rekonRAK",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/rekonRAK`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/rekonRAK")
    ),
  },
  {
    key: "coremenu.gl.laporan.selisihJurnalvsTB",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/selisihJurnalvsTB`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/selisihJurnalvsTB")
    ),
  },
  {
    key: "coremenu.gl.laporan.balanceSheetDetailNew",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/balanceSheetDetailNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/balanceSheetDetailNew")
    ),
  },
  {
    key: "coremenu.gl.laporan.balanceSheetNew",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/balanceSheetNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/balanceSheetNew")
    ),
  },
  {
    key: "coremenu.gl.laporan.profitLossDetailNew",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/profitLossDetailNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/profitLossDetailNew")
    ),
  },
  {
    key: "coremenu.gl.laporan.profitLossNew",
    path: `${APP_PREFIX_PATH}/coremenu/gl/laporan/profitLossNew`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/laporan/profitLossNew")
    ),
  },
  {
    key: "coremenu.gl.setup",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup`,
    component: React.lazy(() => import("views/app-views/coremenu/gl/setup")),
  },
  {
    key: "coremenu.gl.setup.chartOfAccount",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup/chartOfAccount`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/setup/chartOfAccount")
    ),
  },
  {
    key: "coremenu.gl.setup.uploadGLBalance",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup/uploadGLBalance`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/setup/uploadGLBalance")
    ),
  },
  {
    key: "coremenu.gl.setup.setupBalanceSheet",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup/setupBalanceSheet`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/setup/setupBalanceSheet")
    ),
  },
  {
    key: "coremenu.gl.setup.setupBalanceSheetDetail",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup/setupBalanceSheetDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/setup/setupBalanceSheetDetail")
    ),
  },
  {
    key: "coremenu.gl.setup.setupProfitLoss",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup/setupProfitLoss`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/setup/setupProfitLoss")
    ),
  },
  {
    key: "coremenu.gl.setup.setupProfitLossDetail",
    path: `${APP_PREFIX_PATH}/coremenu/gl/setup/setupProfitLossDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/gl/setup/setupProfitLossDetail")
    ),
  },

  {
    key: "coremenu.changePassword",
    path: `${APP_PREFIX_PATH}/coremenu/changePassword`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/changePassword")
    ),
  },
  {
    key: "coremenu.changePassword.securityCode",
    path: `${APP_PREFIX_PATH}/coremenu/changePassword/securityCode`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/changePassword/securityCode")
    ),
  },
  {
    key: "coremenu.changePassword.changePassword",
    path: `${APP_PREFIX_PATH}/coremenu/changePassword/changePassword`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/changePassword/changePassword")
    ),
  },

  {
    key: "coremenu.lending",
    path: `${APP_PREFIX_PATH}/coremenu/lending`,
    component: React.lazy(() => import("views/app-views/coremenu/lending")),
  },
  {
    key: "coremenu.lending.transaksi",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi")
    ),
  },
  {
    key: "coremenu.lending.transaksi.upload",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/upload`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/upload")
    ),
  },
  {
    key: "coremenu.lending.transaksi.validasi",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/validasi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/validasi")
    ),
  },
  {
    key: "coremenu.lending.transaksi.pengajuanPencairan",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/pengajuanPencairan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/pengajuanPencairan")
    ),
  },
  {
    key: "coremenu.lending.transaksi.approvalPencairan",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/approvalPencairan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/approvalPencairan")
    ),
  },
  {
    key: "coremenu.lending.transaksi.disburse",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/disburse`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/disburse")
    ),
  },
  {
    key: "coremenu.lending.transaksi.bpkbAngsuranUpload",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/bpkbAngsuranUpload`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/bpkbAngsuranUpload")
    ),
  },
  {
    key: "coremenu.lending.transaksi.lendingPayment",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/lendingPayment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/lendingPayment")
    ),
  },
  {
    key: "coremenu.lending.transaksi.lendingPaymentPerJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/lendingPaymentPerJatuhTempo`,
    component: React.lazy(() =>
      import(
        "views/app-views/coremenu/lending/transaksi/lendingPaymentPerJatuhTempo"
      )
    ),
  },
  {
    key: "coremenu.lending.transaksi.pelunasanLending",
    path: `${APP_PREFIX_PATH}/coremenu/lending/transaksi/pelunasanLending`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/transaksi/pelunasanLending")
    ),
  },
  {
    key: "coremenu.lending.inquiry",
    path: `${APP_PREFIX_PATH}/coremenu/lending/inquiry`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/inquiry")
    ),
  },
  {
    key: "coremenu.lending.inquiry.approvedLending",
    path: `${APP_PREFIX_PATH}/coremenu/lending/inquiry/approvedLending`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/inquiry/approvedLending")
    ),
  },
  {
    key: "coremenu.lending.inquiry.rejectedLending",
    path: `${APP_PREFIX_PATH}/coremenu/lending/inquiry/rejectedLending`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/inquiry/rejectedLending")
    ),
  },
  {
    key: "coremenu.lending.inquiry.uploadPayment",
    path: `${APP_PREFIX_PATH}/coremenu/lending/inquiry/uploadPayment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/inquiry/uploadPayment")
    ),
  },
  {
    key: "coremenu.lending.inquiry.uploadCollateral",
    path: `${APP_PREFIX_PATH}/coremenu/lending/inquiry/uploadCollateral`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/inquiry/uploadCollateral")
    ),
  },
  {
    key: "coremenu.lending.laporan",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan")
    ),
  },
  {
    key: "coremenu.lending.laporan.uploadHarian",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/uploadHarian`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/uploadHarian")
    ),
  },
  {
    key: "coremenu.lending.laporan.validasi",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/validasi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/validasi")
    ),
  },
  {
    key: "coremenu.lending.laporan.pencairan",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/pencairan`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/pencairan")
    ),
  },
  {
    key: "coremenu.lending.laporan.disbursement",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/disbursement`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/disbursement")
    ),
  },
  {
    key: "coremenu.lending.laporan.kontrakJatuhTempo",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/kontrakJatuhTempo`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/kontrakJatuhTempo")
    ),
  },
  {
    key: "coremenu.lending.laporan.uploadPayment",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/uploadPayment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/uploadPayment")
    ),
  },
  {
    key: "coremenu.lending.laporan.lendingBatch",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/lendingBatch`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/lendingBatch")
    ),
  },
  {
    key: "coremenu.lending.laporan.lendingPayment",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/lendingPayment`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/lendingPayment")
    ),
  },
  {
    key: "coremenu.lending.laporan.lendingPaymentDetail",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/lendingPaymentDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/lendingPaymentDetail")
    ),
  },
  {
    key: "coremenu.lending.laporan.lendingPaymentOSDetail",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/lendingPaymentOSDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/lendingPaymentOSDetail")
    ),
  },
  {
    key: "coremenu.lending.laporan.lendingUsage",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/lendingUsage`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/lendingUsage")
    ),
  },
  {
    key: "coremenu.lending.laporan.pelunasanLending",
    path: `${APP_PREFIX_PATH}/coremenu/lending/laporan/pelunasanLending`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/laporan/pelunasanLending")
    ),
  },
  {
    key: "coremenu.lending.setup",
    path: `${APP_PREFIX_PATH}/coremenu/lending/setup`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/setup")
    ),
  },
  {
    key: "coremenu.lending.setup.mitra",
    path: `${APP_PREFIX_PATH}/coremenu/lending/setup/mitra`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/setup/mitra")
    ),
  },
  {
    key: "coremenu.lending.setup.fasilitas",
    path: `${APP_PREFIX_PATH}/coremenu/lending/setup/fasilitas`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/lending/setup/fasilitas")
    ),
  },

  {
    key: "coremenu.support",
    path: `${APP_PREFIX_PATH}/coremenu/support`,
    component: React.lazy(() => import("views/app-views/coremenu/support")),
  },
  {
    key: "coremenu.support.updateOutstandingPokok",
    path: `${APP_PREFIX_PATH}/coremenu/support/updateOutstandingPokok`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/updateOutstandingPokok")
    ),
  },
  {
    key: "coremenu.support.updateBiayaTarik",
    path: `${APP_PREFIX_PATH}/coremenu/support/updateBiayaTarik`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/updateBiayaTarik")
    ),
  },
  {
    key: "coremenu.support.updateNilaiDendaTetap",
    path: `${APP_PREFIX_PATH}/coremenu/support/updateNilaiDendaTetap`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/updateNilaiDendaTetap")
    ),
  },
  {
    key: "coremenu.support.deleteVoucher",
    path: `${APP_PREFIX_PATH}/coremenu/support/deleteVoucher`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/deleteVoucher")
    ),
  },
  {
    key: "coremenu.support.updatePJJAP",
    path: `${APP_PREFIX_PATH}/coremenu/support/updatePJJAP`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/updatePJJAP")
    ),
  },
  {
    key: "coremenu.support.updateNOPJJ",
    path: `${APP_PREFIX_PATH}/coremenu/support/updateNOPJJ`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/updateNOPJJ")
    ),
  },
  {
    key: "coremenu.support.reGenerateFunding",
    path: `${APP_PREFIX_PATH}/coremenu/support/reGenerateFunding`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/reGenerateFunding")
    ),
  },
  {
    key: "coremenu.support.testReport",
    path: `${APP_PREFIX_PATH}/coremenu/support/testReport`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/testReport")
    ),
  },
  {
    key: "coremenu.support.printMultipleAmortisasi",
    path: `${APP_PREFIX_PATH}/coremenu/support/printMultipleAmortisasi`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/printMultipleAmortisasi")
    ),
  },
  {
    key: "coremenu.support.printMultipleKarpit",
    path: `${APP_PREFIX_PATH}/coremenu/support/printMultipleKarpit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/printMultipleKarpit")
    ),
  },
  {
    key: "coremenu.support.simulasiCreditScoring",
    path: `${APP_PREFIX_PATH}/coremenu/support/simulasiCreditScoring`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/simulasiCreditScoring")
    ),
  },
  {
    key: "coremenu.support.switchBranch",
    path: `${APP_PREFIX_PATH}/coremenu/support/switchBranch`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/support/switchBranch")
    ),
  },

  {
    key: "coremenu.dfs",
    path: `${APP_PREFIX_PATH}/coremenu/dfs`,
    component: React.lazy(() => import("views/app-views/coremenu/dfs")),
  },
  {
    key: "coremenu.dfs.inquiryDaftarKontrak",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/inquiryDaftarKontrak`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/inquiryDaftarKontrak")
    ),
  },
  {
    key: "coremenu.dfs.laporanBiayaCollection",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanBiayaCollection`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanBiayaCollection")
    ),
  },
  {
    key: "coremenu.dfs.laporanStockAsset",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanStockAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanStockAsset")
    ),
  },
  {
    key: "coremenu.dfs.laporanPenarikanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanPenarikanAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanPenarikanAsset")
    ),
  },
  {
    key: "coremenu.dfs.laporanHasilPenjualanAsset",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanHasilPenjualanAsset`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanHasilPenjualanAsset")
    ),
  },
  {
    key: "coremenu.dfs.laporanARDetail",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanARDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanARDetail")
    ),
  },
  {
    key: "coremenu.dfs.laporanDPDCollection",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanDPDCollection`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanDPDCollection")
    ),
  },
  {
    key: "coremenu.dfs.laporanDpdDetail",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanDpdDetail`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanDpdDetail")
    ),
  },
  {
    key: "coremenu.dfs.laporanAROverduebySupplier",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanAROverduebySupplier`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanAROverduebySupplier")
    ),
  },
  {
    key: "coremenu.dfs.laporanCreditDeliquency",
    path: `${APP_PREFIX_PATH}/coremenu/dfs/laporanCreditDeliquency`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/dfs/laporanCreditDeliquency")
    ),
  },
  {
    key: "coremenu.teleservices",
    path: `${APP_PREFIX_PATH}/coremenu/teleservices`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/teleservices")
    ),
  },
  {
    key: "coremenu.teleservices.registerCustomerComplain",
    path: `${APP_PREFIX_PATH}/coremenu/teleservices/registerCustomerComplain`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/teleservices/registerCustomerComplain")
    ),
  },
  {
    key: "coremenu.teleservices.report",
    path: `${APP_PREFIX_PATH}/coremenu/teleservices/report`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/teleservices/report")
    ),
  },
  {
    key: "coremenu.msa",
    path: `${APP_PREFIX_PATH}/coremenu/msa`,
    component: React.lazy(() => import("views/app-views/coremenu/msa")),
  },
  {
    key: "coremenu.msa.simulasiKredit",
    path: `${APP_PREFIX_PATH}/coremenu/msa/simulasiKredit`,
    component: React.lazy(() =>
      import("views/app-views/coremenu/msa/simulasiKredit")
    ),
  },
];
