import fetch from 'auth/FetchInterceptor'

const masterTcService = {}

masterTcService.postMasterTc = function (data) {
    return fetch({
        url: '/reference/MasterTc',
        method: 'post',
        params: data
    })
}

masterTcService.getMaterTcList = function () {
    return fetch({
        url: '/reference/MasterTc',
        method: 'get'
    })
}

masterTcService.getMasterTcDetail = function (data) {
    return fetch({
        url:`/reference/MasterTc/${data.id}`,
        method: 'get'
    })
}

export default masterTcService  