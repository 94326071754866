import fetch from 'auth/FetchMockupInterceptor'

const AuthMockupService = {}

AuthMockupService.login = function (data) {
	return fetch({
		url: '/login',
		method: 'post',
		data: data
	})
}

AuthMockupService.logout = function () {
	return fetch({
		url: '/logout',
		method: 'post'
	})
}

export default AuthMockupService;