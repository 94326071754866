import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import productTcService from "services/Setup/Organization/productTcService";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem(AUTH_TOKEN) || null,
  productTCDetail: {},
  productTcList: {},
};

export const postproductTc = createAsyncThunk(
  "productTc/postproductTc",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.postProductTc(data);
      if (response && response.data) {
        // Memastikan respons dan respons.data tidak undefined
        return response.data;
      } else {
        // Menangani respons yang tidak sesuai dengan harapan
        return rejectWithValue({
          status: "Ok",
          response: response?.data?.message || "Unexpected response!",
        });
      }
    } catch (error) {
      // Menangani error
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const getProductTcList = createAsyncThunk(
  "productTc/getProductTcList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.getProductTcList(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const getProductTcDetail = createAsyncThunk(
  "productTc/getProductTcDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.getProductTcDetail(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const updateProductTc = createAsyncThunk(
  "productTc/updateProductTc",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.updateProductTc(data);
      if (response) {
        return response;
      } else {
        return { status: "Ok", message: "Unexpected response!" };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const deleteProductTc = createAsyncThunk(
  "productTc/deleteProductTc",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.deleteProductTc(data);
      if (response && response.data) {
        // Memastikan respons dan respons.data tidak undefined
        return response.data;
      } else {
        // Menangani respons yang tidak sesuai dengan harapan
        return rejectWithValue({
          status: "Ok",
          response: response?.data?.message || "Unexpected response!",
        });
      }
    } catch (error) {
      // Menangani error
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);
export const returnProductTc = createAsyncThunk(
  "productTc/returnProductTc",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.returnProductTc(data);
      if (response && response.data) {
        // Memastikan respons dan respons.data tidak undefined
        return response.data;
      } else {
        // Menangani respons yang tidak sesuai dengan harapan
        return rejectWithValue({
          status: "Ok",
          response: response?.data?.message || "Unexpected response!",
        });
      }
    } catch (error) {
      // Menangani error
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);
export const approveProductTc = createAsyncThunk(
  "productTc/approveProductTc",
  async (data, { rejectWithValue }) => {
    try {
      const response = await productTcService.approveProductTc(data);
      if (response && response.data) {
        // Memastikan respons dan respons.data tidak undefined
        return response.data;
      } else {
        // Menangani respons yang tidak sesuai dengan harapan
        return rejectWithValue({
          status: "Ok",
          response: response?.data?.message || "Unexpected response!",
        });
      }
    } catch (error) {
      // Menangani error
      return rejectWithValue(error.response?.data?.message || "Catched error!");
    }
  }
);

export const productTcSlice = createSlice({
  name: "productTC",
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideNotification: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    showLoading: (state) => {
      state.loading = true;
    },
  },

  extraReducers: (builder) => {
    builder
      //post
      .addCase(postproductTc.pending, (state) => {
        state.loading = true;
      })
      .addCase(postproductTc.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.productTCDetail = action.payload;
      })
      .addCase(postproductTc.rejected, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
        state.showMessage = true;
      })
      //list
      .addCase(getProductTcList.pending, (state) => {
        state.loading = true;
        state.redirect = "";
      })
      .addCase(getProductTcList.fulfilled, (state, action) => {
        state.loading = false;
        state.productTcList = action.payload;
      })
      .addCase(getProductTcList.rejected, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
        state.showMessage = true;
      })
      //detail
      .addCase(getProductTcDetail.pending, (state) => {
        if (state.productTCDetail == []) {
          state.loading = true;
        }
      })
      .addCase(getProductTcDetail.fulfilled, (state, action) => {
        if (state.productTCDetail == []) {
          state.loading = false;
        }
        state.productTCDetail = action.payload;
      })
      .addCase(getProductTcDetail.rejected, (state, action) => {
        if (state.productTCDetail == []) {
          state.loading = false;
        }
        state.productTCDetail = action.payload;
        state.showMessage = true;
      })

      //update
      .addCase(updateProductTc.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProductTc.fulfilled, (state, action) => {
        state.loading = false;
        // state.redirect = '/'
        // state.productTCDetail = action.payload
      })
      .addCase(updateProductTc.rejected, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
        state.showMessage = true;
      })
      //delete
      .addCase(deleteProductTc.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProductTc.fulfilled, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
      })
      .addCase(deleteProductTc.rejected, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
        state.showMessage = true;
      })
      // Return
      .addCase(returnProductTc.pending, (state) => {
        state.loading = true;
      })
      .addCase(returnProductTc.fulfilled, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
      })
      .addCase(returnProductTc.rejected, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
        state.showMessage = true;
      })
      // Approve
      .addCase(approveProductTc.pending, (state) => {
        state.loading = true;
      })
      .addCase(approveProductTc.fulfilled, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
      })
      .addCase(approveProductTc.rejected, (state, action) => {
        state.loading = false;
        state.productTCDetail = action.payload;
        state.showMessage = true;
      });
  },
});

export const { showNotification, hideNotification, showLoading } =
  productTcSlice.actions;

export default productTcSlice.reducer;
