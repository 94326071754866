import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_TOKEN } from "constants/AuthConstant";
import EmployeeService from 'services/Setup/Organization/employeeService'

export const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: localStorage.getItem(AUTH_TOKEN) || null,
    result: {},
    employeeList: {}
}

export const postEmployee = createAsyncThunk('employee/postEmployee', async (data, { rejectWithValue }) => {
    try {
        const response = await EmployeeService.postEmployee(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', response: 'Unexpected repsonse!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const getEmployeeList = createAsyncThunk('employee/getEmployeeList', async (data, { rejectWithValue }) => {
    try {
        const response = await EmployeeService.getEmployeeList(data)
        if (response) {
            console.log('get list executed')
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const getEmployeeDetail = createAsyncThunk('employee/getEmployeeDetail', async (data, { rejectWithValue }) => {
    try {
        const response = await EmployeeService.getEmployeeDetail(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})


export const updateEmployee = createAsyncThunk('employee/updateEmployee', async (data, { rejectWithValue }) => {
    try {
        const response = await EmployeeService.updateEmployee(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})


export const deleteEmployee = createAsyncThunk('employee/deleteEmployee', async (data, { rejectWithValue }) => {
    try {
        const response = await EmployeeService.deleteEmployee(data)
        if (response) {
            return response
        } else {
            return ({ status: 'Ok', message: 'Unexpected response!' })
        }
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || 'Catched error!')
    }
})

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        showNotification: (state, action) => {
            state.message = action.payload
            state.showMessage = true
            state.loading = false
        },
        hideNotification: (state) => {
            state.message = ''
            state.showMessage = false
        },
        showLoading: (state) => {
            state.loading = true
        }
    },

    extraReducers: (builder) => {
        builder
            //post
            .addCase(postEmployee.pending, (state) => {
                state.loading = true
            })
            .addCase(postEmployee.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.result = action.payload
            })
            .addCase(postEmployee.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //list
            .addCase(getEmployeeList.pending, (state) => {
                state.loading = true
                state.redirect = ''
            })
            .addCase(getEmployeeList.fulfilled, (state, action) => {
                state.loading = false
                state.employeeList = action.payload
                console.log('action')
            })
            .addCase(getEmployeeList.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //detail
            .addCase(getEmployeeDetail.pending, (state) => {
                state.loading = true
            })
            .addCase(getEmployeeDetail.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload
            })
            .addCase(getEmployeeDetail.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //update
            .addCase(updateEmployee.pending, (state) => {
                state.loading = true
            })
            .addCase(updateEmployee.fulfilled, (state, action) => {
                state.loading = false
                state.redirect = '/'
                state.result = action.payload
            })
            .addCase(updateEmployee.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
            //delete
            .addCase(deleteEmployee.pending, (state) => {
                state.loading = true
            })
            .addCase(deleteEmployee.fulfilled, (state, action) => {
                state.loading = false
                state.result = action.payload
            })
            .addCase(deleteEmployee.rejected, (state, action) => {
                state.loading = false
                state.result = action.payload
                state.showMessage = true
            })
    }

})

export const {
    showNotification,
    hideNotification,
    showLoading
} = employeeSlice.actions

export default employeeSlice.reducer