import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY,
	COMPONENTS_PATH 
} from 'configs/AppConfig'
import {MODE} from 'constants/AuthConstant'

const ComponentRoute = () => {
	const { token, selectedBranch, mode } = useSelector(state => state.auth)
	const location = useLocation()

	if (!token && !selectedBranch || !token && mode === 'am' ) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}?${COMPONENTS_PATH}=${location.pathname}`} replace />;
	}

	return <Outlet />
}

export default ComponentRoute